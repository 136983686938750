import {ResponsivePie} from "@nivo/pie";
import {tokens} from "../theme";
import {useTheme} from "@mui/material";
import {useEffect, useState} from "react";
import {getStreamingServiceParticipation} from "../api/api";
import Header from "../components/Header";
import DisplayError from "../components/DisplayError";
import {Box} from "@mui/system";

const StreamingServiceParticipation = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [error, setError] = useState();

    const [chartData, setChartData] = useState([]);
    const [loaded, setLoaded] = useState(false);

    const getChartData = async () => {
        try {
            const response = await getStreamingServiceParticipation();
            // const response = await getApiChartData();
            setChartData(response);
            setLoaded(true);
        } catch (err) {
            setError(err.message)
        }
    }

    useEffect(() => {
        getChartData();
    }, []);

    return (
        <div>
            <Box m="20px">
                <Header title={`Streaming Service Breakdown (${chartData.year})`}
                        subtitle="Streaming Service Participation"/>
                <DisplayError error={error} setError={setError}/>
                <Box height="75vh">
                    {loaded && <ResponsivePie
                        data={chartData.data}
                        theme={{
                            axis: {
                                domain: {
                                    line: {
                                        stroke: colors.grey[100],
                                    },
                                },
                                legend: {
                                    text: {
                                        fill: colors.grey[100],
                                    },
                                },
                                ticks: {
                                    line: {
                                        stroke: colors.grey[100],
                                        strokeWidth: 1,
                                    },
                                    text: {
                                        fill: colors.grey[100],
                                    },
                                },
                            },
                            legends: {
                                text: {
                                    fill: colors.grey[100],
                                },
                            },
                        }}
                        margin={{top: 40, right: 80, bottom: 80, left: 80}}
                        innerRadius={0.5}
                        padAngle={0.7}
                        cornerRadius={3}
                        activeOuterRadiusOffset={8}
                        borderColor={{
                            from: "color",
                            modifiers: [["darker", 0.2]],
                        }}
                        arcLinkLabelsSkipAngle={10}
                        arcLinkLabelsTextColor={colors.grey[100]}
                        arcLinkLabelsThickness={2}
                        arcLinkLabelsColor={{from: "color"}}
                        enableArcLabels={false}
                        arcLabelsRadiusOffset={0.4}
                        arcLabelsSkipAngle={7}
                        arcLabelsTextColor={{
                            from: "color",
                            modifiers: [["darker", 2]],
                        }}
                        defs={[
                            {
                                id: "dots",
                                type: "patternDots",
                                background: "inherit",
                                color: "rgba(255, 255, 255, 0.3)",
                                size: 4,
                                padding: 1,
                                stagger: true,
                            },
                            {
                                id: "lines",
                                type: "patternLines",
                                background: "inherit",
                                color: "rgba(255, 255, 255, 0.3)",
                                rotation: -45,
                                lineWidth: 6,
                                spacing: 10,
                            },
                        ]}
                        legends={[
                            {
                                anchor: "bottom",
                                direction: "row",
                                justify: false,
                                translateX: 0,
                                translateY: 56,
                                itemsSpacing: 0,
                                itemWidth: 100,
                                itemHeight: 18,
                                itemTextColor: "#999",
                                itemDirection: "left-to-right",
                                itemOpacity: 1,
                                symbolSize: 18,
                                symbolShape: "circle",
                                effects: [
                                    {
                                        on: "hover",
                                        style: {
                                            itemTextColor: "#000",
                                        },
                                    },
                                ],
                            },
                        ]}
                    />}
                </Box>
            </Box>
        </div>
    );
};

export default StreamingServiceParticipation;