import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Box} from '@mui/system';
import {getMovieDetails, searchOmdbForMedia, addByImdbId, addToUserCollection} from '../api/api';
import {Button, TextField, Typography, useTheme} from '@mui/material';
import Header from "./Header";
import {Formik} from "formik";
import * as yup from "yup";
import useMediaQuery from "@mui/material/useMediaQuery";
import {tokens} from "../theme";
import DisplayMediaDetails from "./DisplayMediaDetails";
import DisplayError from "./DisplayError";

function MediaAdd() {
    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL
    const {term} = useParams();

    const [results, setResults] = useState([]);
    const [selectedValue, setSelectedValue] = useState("");
    const [selectedId, setSelectedId] = useState("");
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const [open, setOpen] = useState(false);
    const [selectedMovie, setSelectedMovie] = useState(null);
    const theme = useTheme();

    const colors = tokens(theme.palette.mode);

    const styles = {
        card: {
            borderRadius: '10px', // set the border radius to 10px
        },
        paper: {
            borderRadius: '10px',
        },
    };

    console.log("search term: ", term)
    // function to fetch data from API
    const fetchData = async (searchTerm) => {
        if (searchTerm !== '') {
            console.log(`search term: ${searchTerm}`);

            try {
                const response = await searchOmdbForMedia(searchTerm);
                console.log("OMDB:", response)
                const filteredResponse = response.filter((obj) => obj.Type !== "game");
                setResults(filteredResponse);
            } catch (err) {
                console.log("CAUGHT AN ERROR:", err.message)
                setError(err.message)
                setResults([])
            }
        }
    };

    const getDetails = async (imdb_id) => {
        try {
            const response = await getMovieDetails(imdb_id);
            console.log("RESPONSE RETURNED: ", response)
            setSelectedId(imdb_id)
            setSelectedMovie(response);
        } catch (err) {
            console.log("AN ERROR WAS THROWN")
            setError(err.message)
        }
    }

    useEffect(() => {
        if (typeof term !== 'undefined' && term !== '') {
            fetchData(term);
        } else {
            setResults([]);
        }
    }, [term]);

    // function to handle form submission
    const handleFormSubmit = values => {
        // values.preventDefault();
        console.log("values: ", values.searchParameter)
        fetchData(values.searchParameter);
    };

    const handleClear = ({resetForm}) => {
        setResults([])
        setSelectedValue("")
        resetForm({values: {searchParameter: ''}});
    };

    // function to handle adding selected item via API
    const handleAdd = async (collection_id) => {
        try {
            const response = await addByImdbId(collection_id, selectedMovie.imdb_id);
            console.log("RESPONSE RETURNED: ", response)
            // set the collection id to the current collection
            sessionStorage.setItem('current_collection_id', collection_id)
            setResults([])
            setSelectedId('')
            setSelectedValue('')
        } catch (error) {
            console.log('ERROR');
            setError(error.message)
        }
        console.log('added' + {selectedId})
        navigate('/collections')
    };

    // const addMedia = async (collection_id) => {
    //     try {
    //         const movie_id = selectedMovie.id;
    //         console.log("ID: ", movie_id);
    //         await addToUserCollection(collection_id, movie_id);
    //         // set the collection id to the current collection
    //         sessionStorage.setItem('current_collection_id', collection_id)
    //         // await setCurrentCollection(collection_id)
    //         navigate("/collections")
    //     } catch (err) {
    //         handleClose()
    //         if (err && err.message.includes('status code 403')) {
    //             navigate('/auth')
    //         }
    //         setError(err.message)
    //     }
    // }


    // const handleAdd = async () => {
    //     try {
    //         console.log('calling add_by_imdb_id');
    //         //TODO get the collection id and pass it in instead of the 1
    //         const response = await fetch(`${BACKEND_URL}/media/add-by-imdb-id/1/${selectedId}`, {
    //             method: 'GET',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //         });
    //         // const data = await response.json();
    //         const data = await response;
    //         console.log(data);
    //         // setResults(response)
    //         // setSearchTerm('')
    //         setResults([])
    //         setSelectedId('')
    //         setSelectedValue('')
    //         console.log(data);
    //     } catch (error) {
    //         console.log('ERROR');
    //         console.error(error);
    //     }
    //     console.log('added' + {selectedId})
    //     navigate('/collections')
    // };

    function handleRadioChange(event) {
        setSelectedId(event.target.id)
        setSelectedValue(event.target.value);
    }

    function handleClose() {
        setOpen(false);
    }

    const handlePosterClick = (item) => () => {
        console.log("ITEM: ", item);
        var detailedItem = getDetails(item.imdbID)
        console.log("DETAILED: ", detailedItem)
        setOpen(true);
    };

    const initialValues = {
        searchParameter: term || "",
    };

    const checkoutSchema = yup.object().shape({
        searchParameter: yup.string()
            .max(50, "Must be 50 characters or less")
            .required("required"),
    });

    return (
        <div>
            <DisplayError error={error} setError={setError}/>
            <Header title="Media Add Page" subtitle="Add Media To Your Catalog"/>
            <Box m="20px">
                <Formik
                    onSubmit={handleFormSubmit}
                    initialValues={initialValues}
                    validationSchema={checkoutSchema}
                >
                    {({
                          values,
                          errors,
                          touched,
                          handleBlur,
                          resetForm,
                          handleChange,
                          handleSubmit,
                      }) => (
                        <form onSubmit={handleSubmit}>
                            <Box
                                display="grid"
                                gap="30px"
                                gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                                sx={{
                                    "& > div": {gridColumn: isNonMobile ? undefined : "span 4"},
                                }}
                            >
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="Movie Search Term"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.searchParameter}
                                    name="searchParameter"
                                    error={!!touched.searchParameter && !!errors.searchParameter}
                                    helperText={touched.searchParameter && errors.searchParameter}
                                    sx={{gridColumn: "span 6"}}
                                    InputProps={{
                                        style: {fontSize: '20px'},
                                    }}
                                />
                                {touched.searchTerm && errors.searchTerm ? <p>{errors.searchTerm}</p> : null}
                            </Box>
                            <Box display="flex">
                                <Box display="flex" justifyContent="end" mt="20px">
                                    <Button type="button" color="secondary" variant="contained"
                                            onClick={() => handleClear({resetForm})}>
                                        Clear Search
                                    </Button>
                                </Box>
                                <Box display="flex" justifyContent="end" mt="20px" pl="20px">
                                    <Button type="submit" color="secondary" variant="contained">
                                        Search Online Movie DB
                                    </Button>
                                </Box>
                                {selectedValue && (
                                    <Box display="flex">
                                        <Box display="flex" justifyContent="end" mt="20px" pl="20px">
                                            <Button type="button" color="secondary" variant="contained"
                                                    onClick={handleAdd}>
                                                Add &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {selectedValue}
                                            </Button>
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                        </form>
                    )}
                </Formik>
            </Box>
            <Box pl="20px" pr={"20px"}>
                <table width={"100%"}>
                    <tbody>
                    {results.map((item) => (
                        <tr key={item.imdbID}>
                            <td>
                                <input
                                    type="radio"
                                    id={item.imdbID}
                                    name="movie"
                                    value={item.Title}
                                    checked={selectedValue === item.Title}
                                    onChange={handleRadioChange}
                                />
                            </td>
                            <td style={{width: '800px'}}>
                                <Typography pl="20px" variant="h3">
                                    {item.Title}
                                </Typography>
                            </td>
                            <td style={{textAlign: 'center'}}>
                                <img className="poster-image"
                                     src={item.Poster}
                                     alt={item.Title}
                                     height={180}
                                     onClick={handlePosterClick(item)}
                                     style={{cursor: "pointer"}}
                                />
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </Box>
            <DisplayMediaDetails selectedMovie={selectedMovie} open={open} setOpen={setOpen} clickAction={handleAdd}
                                 clickText={"Add"} add={true} removeAction={null} remove={false}/>
            {/*<DisplayMediaDetails selectedMovie={selectedMovie} open={open} setOpen={setOpen} clickAction={addMedia} clickText={"Add"} add={true} removeAction={null} remove={false}/>*/}
        </div>
    );
}

export default MediaAdd;