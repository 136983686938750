import React from 'react';
import { Button } from '@mui/material';

const ButtonWrapper = ({children, onClick, ...otherProps}) => {

    const handleClick = onClick;

    const configButton = {
        variant: 'contained',
        color: 'secondary',
        fullWidth: true,
        onClick: handleClick,
        style: {
            height: '50px' // adjust the height value to your desired height
        }
    }

    return (
        <Button
            {...configButton}
        >
            {children}
        </Button>
    );
};

export default ButtonWrapper;
