import React, {useEffect, useState} from 'react';
import {Card, CardMedia, Grid, useTheme} from "@mui/material";
import {addToUserCollection, deleteFromUserCollection, getCollectionItems} from '../api/api';
import {ClassNames} from "@emotion/react";
import '../App.css';
import Header from "./Header";
import {tokens} from "../theme";
import DisplayMediaDetails from "./DisplayMediaDetails";
import DisplayError from "./DisplayError";
import {useNavigate} from "react-router-dom";


const Collections = () => {
    const [movies, setMovies] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [selectedMovie, setSelectedMovie] = useState(null);
    const [open, setOpen] = useState(false)
    const navigate = useNavigate();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    let collections = JSON.parse(sessionStorage.getItem('collections'));
    let current_collection_id = parseInt(sessionStorage.getItem('current_collection_id'));

    // We are just setting the page name here
    let current_collection_obj = collections.find(coll => coll.id === current_collection_id);
    let collection_name = current_collection_obj ? current_collection_obj.name : 'Not found';
    let page_title = collection_name + " Collection"

    const [reload, setReload] = useState(false);

    const styles = {
        card: {
            borderRadius: '10px',
        },
        paper: {
            borderRadius: '10px',
        },
    };

    const handleDropdownSelect = (event) => {
        current_collection_id = event.target.value;
        sessionStorage.setItem('current_collection_id', current_collection_id)
        console.log('Selected item: ', event.target.value);
        getMovies();
    };


    const handleOpen = (movie) => {
        setSelectedMovie(movie);
        setOpen(true);
    };

    const setCollectionDropdown = async () => {
        console.log("//TODO add logic or remove setCollectionDropdpwn()")
    }

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        getMovies();
        setCollectionDropdown();
    }, [current_collection_id]);

    const getMovies = async () => {
        try {
            const response = await getCollectionItems(current_collection_id);
            console.log("personal catalog", response);
            setMovies(response);
            setLoading(true);
        } catch (err) {
            if (err && err.message.includes('status code 403')) {
                navigate('/auth')
            }
            setError(err.message)
        }
    }

    const removeMedia = async () => {
        try {
            const movie_id = selectedMovie.id;
            const response = await deleteFromUserCollection(current_collection_id, movie_id);
            handleClose();
            getMovies();
        } catch (err) {
            setError(err.message)
        }
    }

    const moveMedia = async (collection_id) => {
        try {
            const movie_id = selectedMovie.id;
            console.log("ID: ", movie_id);
            await addToUserCollection(collection_id, movie_id);
            // set the collection id to the current collection
            sessionStorage.setItem('current_collection_id', collection_id)
            // await setCurrentCollection(collection_id)
            setSelectedMovie(null)
            setReload(!reload);
            navigate("/collections")
        } catch (err) {
            handleClose()
            if (err && err.message.includes('status code 403')) {
                navigate('/auth')
            }
            setError(err.message)
        }
    }

    return (
        <div>
            <DisplayError error={error} setError={setError}/>
            <Header title={page_title} items={collections} current_item={current_collection_id} displayField='name'
                    handleSelect={handleDropdownSelect}/>
            <Grid container spacing={1} style={{paddingLeft: 2}}>
                {loading && movies.map((movie) => (
                    <Grid key={movie.imdb_id} justifyContent="flex-start" alignItems="stretch" item xs={3} md={2.4}
                          lg={2} style={{justifyContent: "center", display: "flex", flexDirection: "column"}}>
                        <div>
                            <Card style={styles.card} onClick={() => handleOpen(movie)}>
                                <CardMedia
                                    className={ClassNames.media}
                                    component="img"
                                    height='300'
                                    width='100%'
                                    image={movie.poster}
                                    alt={movie.title}>
                                </CardMedia>
                            </Card>
                        </div>
                    </Grid>
                ))}
            </Grid>
            { selectedMovie &&
                <DisplayMediaDetails selectedMovie={selectedMovie} open={open} setOpen={setOpen} clickAction={moveMedia} clickText={"Move"} add={true} removeAction={removeMedia} remove={true}/>
            }
        </div>);
}

export default Collections