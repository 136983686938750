import axios from 'axios';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL

const instance = axios.create({
  baseURL: BACKEND_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

async function callApi(method, path, data) {
  console.log("URL: ", BACKEND_URL);
  console.log("callAPI: ", path);
  try {
    const response = await instance({
      method: method,
      url: path,
      data: data,
      withCredentials: true
    });
    return response.data;
  } catch (error) {
    // if (error.response && error.response.status === 401 && error.response.data === '{"token_refreshed": true}') {
    if (error.response.status === 401) {
      console.log("TOKEN REFRESHED");
      const retryResponse = await instance({
        method: method,
        url: path,
        data: data,
        withCredentials: true
      });
      return retryResponse.data;
    } else {
      console.error(`Error calling API: ${error.message}`);
      throw new Error(`${error.message}`);
    }
  }
}


export async function login(email, password) {
  try {
    var data = JSON.stringify({ "email": email, "password": password });
    const response = await callApi('post', '/user/login', data);

    return response;
  } catch (error) {
    console.log(error.message)
    throw new Error(error.message)
  }
}


export async function logout() {
  try {
    const response = await callApi('post', '/user/logout');

    return response;
  } catch (error) {
    console.log(error.message)
    throw new Error(error.message)
  }
}

export async function getCatalogData() {
  console.log('getCatalogData called');
  try {
    const data = await callApi('get', '/media/get-catalog-for-user');
    console.log('getCatalogData call succeeded');
    return data;
  } catch (error) {
    console.log("error in getCatalogData")
    throw new Error(error.message)
  }
}


export async function getCatalogDataByGenre(genre_id) {
  try {
    return await callApi('get', `/media/get-catalog-for-user-by-genre/${genre_id}`)
  } catch (error) {
    console.log("error in getCatalogDataByGenre")
    throw new Error(error.message)
  }
}


export async function getCollectionItems(collection_id) {
  try {
    return await callApi('get', `/media/get-collection-items/${collection_id}`)
  } catch (error) {
    console.log("error in getCollectionData")
    throw new Error(error.message)
  }
}

export async function searchOmdbForMedia(search_term) {
  try {
    const url = `/media/search-by-name/${search_term}`;
    const response = await callApi('get', url)

    if(response == null) {
      console.log("no matching media located")
      throw new Error("no matching media located")
    }

    return response
  } catch (error) {
    console.log("error in searchOmdbForMedia")
    throw new Error(error.message)
  }
}

export async function getMovieDetails(imdb_id) {
  try {
    const url = `/media/get-movie-details-by-imdb-id/${imdb_id}`;
    console.log("Calling URL: ", url)
    return await callApi('get', url)
  } catch (error) {
    console.log("error in getMovieDetails")
    throw new Error(error.message)
  }
}

export async function getEventsForCatalog() {
  try {
    const url = `/event/get-events-for-catalog`;
    console.log("Calling URL: ", url)
    return await callApi('get', url)
  } catch (error) {
    console.log("error in getEventsForCatalog")
    throw new Error(error.message)
  }
}

export async function getCalendarEventsForCatalog() {
  try {
    const url = `/event/get-calendar-events-for-catalog`;
    console.log("Calling URL: ", url)
    return await callApi('get', url)
  } catch (error) {
    console.log("error in getCalendarEventsForCatalog")
    throw new Error(error.message)
  }
}

export async function getMovieRevenueByType() {
  try {
    const url = `/chart/get-movie-revenue-by-movie-type`;
    console.log("Calling URL: ", url)
    return await callApi('get', url)
  } catch (error) {
    console.log("error in getMovieRevenueByType")
    throw new Error(error.message)
  }
}

export async function getUserParticipation() {
  try {
    const url = `/chart/get-user-participation`;
    console.log("Calling URL: ", url)
    const response = await callApi('get', url)
    console.log("RESPONSE: ", response)
    console.log("RESPONSE Data: ", response.data)

    return response
  } catch (error) {
    console.log("error in getUserParticipation")
    throw new Error(error.message)
  }
}

export async function getStreamingServiceParticipation() {
  try {
    const url = `/chart/get-streaming-service-participation`;
    console.log("Calling URL: ", url)
    const response = await callApi('get', url)
    console.log("RESPONSE: ", response)
    console.log("RESPONSE Data: ", response.data)

    return response
  } catch (error) {
    console.log("error in getStreamingServiceParticipation")
    throw new Error(error.message)
  }
}


export async function getUserLocation() {
  try {
    const url = `/chart/get-user-location`;
    console.log("Calling URL: ", url)
    const response = await callApi('get', url)
    console.log("RESPONSE: ", response)
    console.log("RESPONSE Data: ", response.data)

    return response
  } catch (error) {
    console.log("error in getUserLocation")
    throw new Error(error.message)
  }
}

export async function getClubs() {
  try {
    const url = `/club/get-clubs`;
    console.log("Calling URL: ", url)
    const response = await callApi('get', url)
    console.log("RESPONSE: ", response)
    console.log("RESPONSE Data: ", response.data)

    return response
  } catch (error) {
    console.log("error in getUserClubs")
    throw new Error(error.message)
  }
}

export async function getUserProfile(user_id) {
  try {
    const url = `/user/get-profile/${user_id}`;
    console.log("Calling URL: ", url)
    const response = await callApi('get', url)
    console.log("RESPONSE: ", response)

    return response
  } catch (error) {
    console.log("error in getUserProfile")
    throw new Error(error.message)
  }
}

export async function getZipDetails(zipcode) {
  try {
    const url = `/zipcode/get-zipcode/${zipcode}`;
    console.log("Calling URL: ", url)
    const response = await callApi('get', url)
    console.log("RESPONSE: ", response)

    return response
  } catch (error) {
    console.log("error in getZipDetails")
    throw new Error(error.message)
  }
}

export async function updateProfile(data) {
  try {
    const url = `/user/profile-update`;
    console.log("Calling URL: ", url)
    const response = await callApi('post', url, data);
    console.log("RESPONSE: ", response)

    return response
  } catch (error) {
    console.log("error in updateProfile")
    console.log(error.message())
    console.log(error)
    throw new Error(error.message)
  }
}

export async function getUserList() {
  try {
    const url = `/user/get-user-list`;
    console.log("Calling URL: ", url)
    const response = await callApi('get', url);
    console.log("RESPONSE: ", response)

    return response
  } catch (error) {
    console.log(error)
    throw new Error(error.message)
  }
}

export async function getUserCollections() {
  try {
    const url = `/collection/get-collections`;
    console.log("Calling URL: ", url)
    const response = await callApi('get', url)
    console.log("getUserCollections: ", response)
    console.log("getUserCollections Data: ", response.data)

    return response
  } catch (error) {
    console.log("error in getUserCollections")
    throw new Error(error.message)
  }
}

export async function addToUserCollection(collection_id, media_id) {
  try {
    const url = `/collection/add-media-to-collection/${collection_id}/${media_id}`;
    console.log("Calling URL: ", url)
    const response = await callApi('get', url)
    console.log("addToUserCollection: ", response)
    console.log("addToUserCollection Data: ", response.data)

    return response
  } catch (error) {
    console.log("error in addToUserCollection")
    throw new Error(error.message)
  }
}

export async function deleteFromUserCollection(collection_id, media_id) {
  try {
    const url = `/collection/delete-media-from-collection/${collection_id}/${media_id}`;
    console.log("Calling URL: ", url)
    const response = await callApi('get', url)
    console.log("deleteFromUserCollection: ", response)
    console.log("deleteFromUserCollection Data: ", response.data)

    return response
  } catch (error) {
    console.log("error in deleteFromUserCollection")
    throw new Error(error.message)
  }
}


export async function setCurrentCollection(collection_id) {
  try {
    const url = `/ui-control/set-current-collection-id/${collection_id}`;
    console.log("Calling URL: ", url)
    const response = await callApi('get', url)
    console.log("setCurrentCollection: ", response)
    console.log("setCurrentCollection Data: ", response.data)

    return response
  } catch (error) {
    console.log("error in setCurrentCollection")
    throw new Error(error.message)
  }
}


export async function getUserRanking(media_id) {
  try {
    // const url = `/media/get-user-ranking/${media_id}`;
    const url = `/media/get-user-ranking/${media_id}`;
    console.log("Calling URL: ", url)
    const response = await callApi('get', url)
    console.log("getUserRanking: ", response)
    console.log("getUserRanking Data: ", response.data)

    return response
  } catch (error) {
    console.log("error in getUserRanking")
    throw new Error(error.message)
  }
}

export async function setUserRanking(media_id, rank) {
  try {
    const url = `/media/set-user-ranking/${media_id}/${rank}`;
    console.log("Calling URL: ", url)
    const response = await callApi('get', url)
    console.log("setUserRanking: ", response)
    console.log("setUserRanking Data: ", response.data)

    return response
  } catch (error) {
    console.log("error in setUserRanking")
    throw new Error(error.message)
  }
}

export async function getGenres() {
  try {
    const url = `/media/get-genres`;
    console.log("Calling URL: ", url)
    const response = await callApi('get', url)
    console.log("getGenres: ", response)

    return response
  } catch (error) {
    console.log("error in getGenres")
    throw new Error(error.message)
  }
}

export async function getSubscriptions() {
  try {
    const url = `/subscription/get-subscriptions`;
    console.log("Calling URL: ", url)
    const response = await callApi('get', url)
    console.log("getSubscriptions: ", response)

    return response
  } catch (error) {
    console.log("error in getSubscriptions")
    throw new Error(error.message)
  }
}

export async function getSubscription(subscription_id) {
  try {
    const url = `/subscription/get-subscription/${subscription_id}`;
    console.log("Calling URL: ", url)
    const response = await callApi('get', url)
    console.log("getSubscription: ", response)

    return response
  } catch (error) {
    console.log("error in getSubscription")
    throw new Error(error.message)
  }
}

export async function getSubscriptionProviders() {
  try {
    const url = `/subscription/get-subscription-providers`;
    console.log("Calling URL: ", url)
    const response = await callApi('get', url)
    console.log("getSubscriptionProviders: ", response)

    return response
  } catch (error) {
    console.log("error in getSubscriptionProviders")
    throw new Error(error.message)
  }
}


export async function getSubscriptionProvidersForDropdown() {
  try {
    const url = `/subscription/get-subscription-providers-for-dropdown`;
    console.log("Calling URL: ", url)
    const response = await callApi('get', url)
    console.log("getSubscriptionProvidersForDropdown: ", response)

    return response
  } catch (error) {
    console.log("error in getSubscriptionProvidersForDropdown")
    throw new Error(error.message)
  }
}

export async function updateSubscription(subscription) {

  subscription.additional_providers = subscription.additional_providers.map(function(item) {
    return {
      id: item.id,
      provider_name: item.label
    };
  });


  try {
    const url = `/subscription/update-subscription`;
    console.log("Calling URL: ", url)
    console.log("Sending Subscription: ", subscription)
    const response = await callApi('post', url, subscription)
    console.log("updateSubscription: ", response)

    return response
  } catch (error) {
    console.log("error in updateSubscription")
    throw new Error(error.message)
  }
}

export async function deleteSubscription(subscription_id) {
  try {
    const url = `/subscription/delete-subscription/${subscription_id}`;
    console.log("Calling URL: ", url)
    const response = await callApi('get', url)
    console.log("deleteSubscription: ", response)

    return response
  } catch (error) {
    console.log("error in deleteSubscription")
    throw new Error(error.message)
  }
}


export async function addSubscription(subscription) {

  subscription.additional_providers = subscription.additional_providers.map(function(item) {
    return {
      id: item.id,
      provider_name: item.label
    };
  });

  try {
    const url = `/subscription/add-subscription`;
    console.log("Calling URL: ", url)
    console.log("Sending Subscription: ", subscription)
    const response = await callApi('post', url, subscription)
    console.log("addSubscription: ", response)

    return response
  } catch (error) {
    console.log("error in addSubscription")
    throw new Error(error.message)
  }
}

export async function getCollections() {
  try {
    const url = `/collection/get-collections`;
    console.log("Calling URL: ", url)
    const response = await callApi('get', url)
    console.log("getCollections: ", response)

    return response
  } catch (error) {
    console.log("error in getCollections")
    throw new Error(error.message)
  }
}

export async function getCollection(collection_id) {
  try {
    const url = `/collection/get-collection/${collection_id}`;
    console.log("Calling URL: ", url)
    const response = await callApi('get', url)
    console.log("getCollection: ", response)

    return response
  } catch (error) {
    console.log("error in getCollection")
    throw new Error(error.message)
  }
}

export async function updateCollection(collection) {

  try {
    const url = `/collection/update-collection`;
    console.log("Calling URL: ", url)
    console.log("Sending Collection: ", collection)
    const response = await callApi('post', url, collection)
    console.log("updateCollection: ", response)

    return response
  } catch (error) {
    console.log("error in updateCollection")
    throw new Error(error.message)
  }
}

export async function addCollection(collection) {

  try {
    const url = `/collection/add-collection`;
    console.log("Calling URL: ", url)
    console.log("Sending Collection: ", collection)
    const response = await callApi('post', url, collection)
    console.log("addCollection: ", response)

    return response
  } catch (error) {
    console.log("error in addCollection")
    throw new Error(error.message)
  }
}

export async function deleteCollection(collection_id) {
  try {
    const url = `/collection/delete-collection/${collection_id}`;
    console.log("Calling URL: ", url)
    const response = await callApi('get', url)
    console.log("deleteCollection: ", response)

    return response
  } catch (error) {
    console.log("error in deleteCollection")
    throw new Error(error.message)
  }
}

export async function addByImdbId(collection_id, imdb_id) {
  try {
    const url = `/media/add-by-imdb-id/${collection_id}/${imdb_id}`;
    console.log("Calling URL: ", url)
    const response = await callApi('get', url)
    console.log("addByImdbId: ", response)
    console.log("addByImdbId Data: ", response.data)

    return response
  } catch (error) {
    console.log("error in addByImdbId")
    throw new Error(error.message)
  }
}
