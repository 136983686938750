import {
    Box,
    Container,
    Grid,
    Typography,
    useTheme
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from ".//Header";
import DisplayError from "./DisplayError";
import React, {useEffect, useState} from "react";
import * as Yup from "yup";
import {ErrorMessage, Field, Form, Formik} from "formik";
import {tokens} from "../theme";
import {useNavigate} from "react-router-dom";
import Textfield from "./FormsUI/Textfield";
import Select from "./FormsUI/Select";
import DateTimePicker from "./FormsUI/DateTimePicker";
import Checkbox from "./FormsUI/Checkbox";
import Button from "./FormsUI/Button";
import countries from "../data/countries.json";
import {getClubs} from "../api/api";

const INITIAL_FORM_STATE = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    country: '',
    arrivalDate: '',
    departureDate: '',
    message: '',
    termsOfService: false
};

const FORM_VALIDATION = Yup.object().shape({
    firstName: Yup.string()
        .required('Required'),
    lastName: Yup.string()
        .required('Required'),
    email: Yup.string()
        .email('Invalid email.')
        .required('Required'),
    phone: Yup.number()
        .integer()
        .typeError('Please enter a valid phone number')
        .required('Required'),
    addressLine1: Yup.string()
        .required('Required'),
    addressLine2: Yup.string(),
    city: Yup.string()
        .required('Required'),
    state: Yup.string()
        .required('Required'),
    country: Yup.string()
        .required('Required'),
    arrivalDate: Yup.date()
        .required('Required'),
    departureDate: Yup.date()
        .required('Required'),
    message: Yup.string(),
    termsOfService: Yup.boolean()
        .oneOf([true], 'The terms and conditions must be accepted.')
        .required('The terms and conditions must be accepted.'),
});


const ManageClubs = () => {
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const [error, setError] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const theme = useTheme();
    // const classes = useStyles();
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate();

    const [dropdownOptions, setDropdownOptions] = useState([]);

    const getUserClubs = async () => {
        try {
            const response = await getClubs();
            setDropdownOptions(response);
            setLoaded(true);
        } catch (err) {
            setError(err.message)
        }
    }

    useEffect(() => {
        getUserClubs();
    }, []);
    const handleSetClub = async () => {
        try {
            console.log('set current club to', selectedValue);
        } catch (error) {
            console.log('ERROR');
            console.error(error);
        }
    };

    const [selectedValue, setSelectedValue] = useState('');

    const handleSelect = (event) => {
        setSelectedValue(event.target.value);
    };


    return (
        <Box m="20px">
            <Grid container>
                <Grid item xs={12}>
                    <DisplayError error={error} setError={setError}/>
                    <Header title="Manage Clubs" subtitle="Manage Movie Clubs"/>
                </Grid>
                <Grid item xs={12}>
                    <Container maxWidth="md">
                        {/*<div className={classes.formWrapper}>*/}
                        <div>
                            {loaded &&
                                <Box>
                                    <Formik
                                        initialValues={{}}
                                        validationSchema={FORM_VALIDATION}
                                        onSubmit={values => {
                                            console.log("VALUES: ", values)
                                        }}>
                                        <Form>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <Typography>
                                                        Your Details
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Textfield
                                                        name="firstName"
                                                        label="First Name"
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Textfield
                                                        name="lastName"
                                                        label="Last Name"
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Textfield
                                                        name="email"
                                                        label="Email"
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Textfield
                                                        name="phone"
                                                        label="Phone"
                                                    />
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Typography>
                                                        Address
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Textfield
                                                        name="addressLine1"
                                                        label="Address Line 1"
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Textfield
                                                        name="addressLine2"
                                                        label="Address Line 2"
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Textfield
                                                        name="city"
                                                        label="City"
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Textfield
                                                        name="state"
                                                        label="State"
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Select
                                                        name="country"
                                                        label="Country"
                                                        options={countries}
                                                    />
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Typography>
                                                        Booking Information
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <DateTimePicker
                                                        name="arrivalDate"
                                                        label="Arrival Date"
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <DateTimePicker
                                                        name="departureDate"
                                                        label="Departure Date"
                                                    />
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Textfield
                                                        name="message"
                                                        label="Message"
                                                        multiline={true}
                                                        rows={4}
                                                    />
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Checkbox
                                                        name="termsOfService"
                                                        legend="Terms Of Service"
                                                        label="I Agree"

                                                    />
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Button>
                                                        Submit Form
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Form>
                                    </Formik>
                                </Box>
                            }
                        </div>
                    </Container>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ManageClubs;