import {Alert, Grid, Typography} from "@mui/material";

import React, {useState} from "react";
import useAuth from "../hooks/useAuth";
import Profile from "./Profile";
import DisplayError from "./DisplayError";
import Header from "./Header";
import {Box} from "@mui/system";

const ManageProfile = () => {
    const { auth } = useAuth();
    const { user_id } = auth;
    const [error, setError] = useState(null);

    return(
        <Box m="20px">
            <Grid container>
                <Grid item xs={12}>
                    <DisplayError error={error} setError={setError}/>
                    <Header title="Profile" subtitle="Edit Personal Profile"/>
                </Grid>
                <Profile user_id={ user_id } />
            </Grid>
        </Box>
    );
}

export default ManageProfile;