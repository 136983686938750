import {
    Box,
    Container, Divider,
    Grid, InputLabel, MenuItem, OutlinedInput, Paper, SelectChangeEvent,
    Table, TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    useTheme
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from ".//Header";
import DisplayError from "./DisplayError";
import React, {useEffect, useState} from "react";
import * as Yup from "yup";
import {Form, Formik, useFormikContext} from "formik";
import {
    getSubscriptions,
    getSubscription,
    getSubscriptionProvidersForDropdown,
    updateSubscription, deleteSubscription, addSubscription
} from "../api/api";
import {tokens} from "../theme";
import Textfield from "./FormsUI/Textfield";
import Select from "./FormsUI/Select";
import DateTimePicker from "./FormsUI/DateTimePicker";
import Checkbox from "./FormsUI/Checkbox";
import Button from "./FormsUI/Button";
import FormlessButton from "./FormsUI/FormlessButton";
import MultiSelectAutocompleteWrapper from "./FormsUI/MultiSelectAutocomplete";


const FORM_VALIDATION = Yup.object().shape({
    subscription_name: Yup.string()
        .required('Required'),
    send_notifications: Yup.boolean(),
    cost: Yup.string()
        .required('Required'),
    frequency: Yup.string()
        .required('Required'),
    start_date: Yup.date()
        .required('Required'),
    due_date: Yup.string(),
    due_day: Yup.string(),
    notes: Yup.string(),
});

const frequency = {
    "daily": "DAILY",
    "weekly": "WEEKLY",
    "monthly": "MONTHLY",
    "quarterly": "QUARTERLY",
    "biannually": "BIANNUALLY",
    "annually": "ANNUALLY"
}

const ManageSubscriptions = () => {
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const [error, setError] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [sortFunction, setSortFunction] = React.useState(() => (a, b) => a.id - b.id);

    const [subscriptionProviders, setSubscriptionProviders] = useState([]);
    const [subscriptions, setSubscriptions] = useState([]);
    const [selectedSubscription, setSelectedSubscription] = useState();
    const [totalMonthlyCost, setTotalMonthlyCost] = useState();

    const retrieveSubscriptionProviders = async () => {
        try {
            const response = await getSubscriptionProvidersForDropdown();
            setSubscriptionProviders(response);
            // setLoaded(true);
        } catch (err) {
            setError(err.message)
        }
    }

    const retrieveSubscriptions = async () => {
        try {
            const response = await getSubscriptions();
            setSubscriptions(response);
            console.log("SUBS: ", response);
            let total_monthly_cost = response.reduce((totalCost, subscription) => {
                return totalCost + parseFloat(subscription.monthly_cost);
            }, 0);
            total_monthly_cost = total_monthly_cost.toFixed(2);
            console.log("MONTHLY: ", total_monthly_cost);
            setTotalMonthlyCost(total_monthly_cost);
            setLoaded(true);
        } catch (err) {
            setError(err.message)
        }
    }

    useEffect(() => {
        retrieveSubscriptionProviders();
        retrieveSubscriptions();
    }, []);

    const handleSort = (field) => {
        setSortFunction(() => {
            return (a, b) => {
                if (a[field] < b[field]) return -1;
                if (a[field] > b[field]) return 1;
                return 0;
            };
        });
    };

    const handleMoneySort = (field) => {
        setSortFunction(() => {
            return (a, b) => {
                // assuming your object properties are in the form of 25.25, multiply by 100
                const aVal = a[field] * 100;
                const bVal = b[field] * 100;

                if (aVal < bVal) return -1;
                if (aVal > bVal) return 1;
                return 0;
            };
        });
    };

    const handleFrequencySort = (field) => {
        setSortFunction(() => {
            // Convert your frequency object into an array of keys
            const ordering = Object.keys(frequency);
            return (a, b) => {
                // Compare the order values of a[field] and b[field]
                return ordering.indexOf(a[field]) - ordering.indexOf(b[field]);
            };
        });
    };

    const handleAddClick = () => {
        // setSelectedSubscription({});
        setSelectedSubscription({
            id: null,
            subscription_name: '',
            send_notifications: true,
            cost: '0.00',
            frequency: "annually",
            start_date: null,
            due_date: '',
            due_day: '',
            day_of_week: null,
            notes: '',
            primary_provider_id: 1,
            additional_providers: [],
        });
    }

    const handleClick = async (event, id) => {
        // Handle the click event, you may want to navigate or load additional data
        console.log(`Row with id ${id} was clicked.`);

        const subscriptionData = await getSubscription(id);
        if (subscriptionData?.notes === null) {
            subscriptionData.notes = ''
        }

        let newData = subscriptionData.additional_providers.map(item => {
            return {
                id: item.id.toString(),
                label: item.provider_name
            };
        });

        setSelectedSubscription({
            id: subscriptionData.id,
            subscription_name: subscriptionData.subscription_name,
            send_notifications: subscriptionData.send_notifications,
            cost: subscriptionData.cost,
            frequency: subscriptionData.frequency,
            start_date: subscriptionData.start_date,
            due_date: subscriptionData.due_date,
            due_day: subscriptionData.due_day,
            day_of_week: subscriptionData.day_of_week,
            notes: subscriptionData.notes,
            primary_provider_id: subscriptionData.primary_provider.id,
            additional_providers: newData,
        });
    };


    const handleSubmit = async (values) => {
        try {
            if (values.id) {
                await updateSubscription(values);
            } else {
                await addSubscription(values);
            }
            await retrieveSubscriptions(); // refreshes the list of subscriptions
        } catch (e) {
            console.error('Error adding/updating subscription', e);
        }
        setSelectedSubscription(null); // closes the form
    };

    const handleCloseForm = async () => {
        setSelectedSubscription(null); // closes the form
    };

    const handleDeleteSubscription = async (id) => {
        if (window.confirm("Are you sure?")) {
            console.log("DELETING", id);
            await deleteSubscription(id)
            await retrieveSubscriptions();
        }
        setSelectedSubscription(null);
    }

    return (
        <>
            <Box m="20px">
                <Grid container>
                    <Grid item xs={12}>
                        <DisplayError error={error} setError={setError}/>
                        <Header title="Manage Subscriptions" subtitle="Manage Subscriptionss"/>
                    </Grid>
                    <Grid item xs={12}>
                        <Container maxWidth="md">
                            {/*<div className={classes.formWrapper}>*/}
                            <>
                                {loaded &&
                                    <Grid>
                                        <TableContainer component={Paper} sx={{
                                            minWidth: 650,
                                            maxWidth: '100%',
                                            backgroundColor: 'inherit'
                                        }}>
                                            <Table sx={{minWidth: 650}} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        {/*<TableCell onClick={() => handleSort('id')}>ID</TableCell>*/}
                                                        <TableCell
                                                            sx={{ fontWeight: 'italic', fontSize: '1.2em', color: colors.greenAccent[400] }} onClick={() => handleSort('subscription_name')}>Name</TableCell>
                                                        <TableCell sx={{ fontWeight: 'italic', fontSize: '1.2em', color: colors.greenAccent[400] }} onClick={() => handleSort('due_date')}>Due
                                                            Date</TableCell>
                                                        <TableCell
                                                            sx={{ fontWeight: 'italic', fontSize: '1.2em', color: colors.greenAccent[400] }} onClick={() => handleFrequencySort('frequency')}>Frequency</TableCell>
                                                        <TableCell align="right"
                                                                   sx={{ fontWeight: 'italic', fontSize: '1.2em', color: colors.greenAccent[400] }} onClick={() => handleMoneySort('monthly_cost')}>Monthly
                                                            Cost</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {subscriptions.sort(sortFunction).map((row) => (
                                                        <TableRow key={row.id}
                                                                  onClick={(event) => handleClick(event, row.id)}>
                                                            {/*<TableCell component="th" scope="row">*/}
                                                            {/*    {row.id}*/}
                                                            {/*</TableCell>*/}
                                                            <TableCell component="th" scope="row">{
                                                                row.subscription_name}
                                                            </TableCell>
                                                            <TableCell>{row.due_date}</TableCell>
                                                            <TableCell>{row.frequency.charAt(0).toUpperCase() + row.frequency.slice(1)}</TableCell>
                                                            <TableCell
                                                                align="right">{parseFloat(row.monthly_cost).toFixed(2)}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                }
                            </>
                            <Divider sx={{my: 1, height: 2}}/>
                            <div>
                                {!selectedSubscription &&
                                    <Grid container spacing={2}>
                                        <Grid item xs={2}>
                                            <FormlessButton onClick={handleAddClick}>
                                                Add
                                            </FormlessButton>
                                        </Grid>
                                        <Grid item xs={8}/>
                                        {/*<Grid item xs={1} style={{ textAlign: 'right' }}>*/}
                                        <Grid item xs={1}>
                                            Total:
                                        </Grid>
                                        <Grid item xs={1} style={{textAlign: 'right'}}>
                                            ${totalMonthlyCost}
                                        </Grid>
                                    </Grid>
                                }
                            </div>
                            <Divider sx={{my: 2, bgcolor: colors.greenAccent[400], height: 2}}/>
                            {/*<Box sx={{ height: '10px', bgcolor: 'text.primary', my: 10 }} />*/}
                            <div>
                                {loaded &&
                                    <Box>
                                        {selectedSubscription ? (
                                            <SubscriptionForm
                                                key={selectedSubscription.id}
                                                initialValues={selectedSubscription}
                                                subscriptionProviders={subscriptionProviders}
                                                handleSubmit={handleSubmit}
                                                deleteClick={handleDeleteSubscription}
                                                closeClick={handleCloseForm}
                                            />
                                        ) : null}
                                    </Box>
                                }
                            </div>
                        </Container>
                    </Grid>
                </Grid>
            </Box>
        </>);
};

const SubscriptionForm = ({initialValues, subscriptionProviders, handleSubmit, deleteClick, closeClick}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const optionsArray = Object.keys(subscriptionProviders).map(key => {
        return {
            id: key,
            label: subscriptionProviders[key]
        };
    });

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={FORM_VALIDATION}
            enableReinitialize={true}
            onSubmit={(values) => {
                handleSubmit(values);
            }}>
            <Form>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h3" sx={{color: colors.greenAccent[400]}}>
                            Subscription Details
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Textfield
                            name="subscription_name"
                            label="Subscription Name"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Select
                            name="primary_provider_id"
                            label="Primary Provider"
                            options={subscriptionProviders}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <MultiSelectAutocompleteWrapper
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            name="additional_providers"
                            options={optionsArray}
                            label="Additional Providers"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Textfield
                            name="cost"
                            label="Cost"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <DateTimePicker
                            name="start_date"
                            label="Start Date"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <DateTimePicker
                            name="due_date"
                            label="Due Date"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Select
                            name="frequency"
                            label="Frequency"
                            options={frequency}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Checkbox
                            name="send_notifications"
                            label="Send Notifications"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Textfield
                            name="notes"
                            label="Notes"
                            multiline={true}
                            rows={4}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Button>
                            Submit Subscription
                        </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button onClick={() => deleteClick(initialValues.id)}>
                            Delete Subscription
                        </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button onClick={closeClick}>
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </Form>
        </Formik>
    )
}
export default ManageSubscriptions;