import {Box, useTheme} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DisplayError from "./DisplayError";
import Header from "./Header";
import React, {useState} from "react";
import {tokens} from "../theme";


const FAQ = () => {
    const [error, setError] = useState(null);

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <div>
            <DisplayError error={error} setError={setError}/>
            <Box m="20px">
                <Header title="FAQ" subtitle="Frequently Asked Questions"/>

                <Accordion defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                        <Typography color={colors.greenAccent[500]} variant="h3">
                            What is CouchRanking?
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="h4">
                            CouchRanking is a media sharing website with a personal touch.  The site
                            offers small Movie Clubs and private Movie Lounges to provide an environment
                            where users can discuss movies and media with family and close friends.  Think
                            of it as a "Media Twitter" for family and friends.
                            <br/><br/>
                            In addition, CouchRanking provides many useful services as users try to
                            navigate the ever more confusing media landscape that consumers are facing
                            as media services fracture, each offering their own specific content.
                            <br/><br/>
                            Check out our tools that assist in tracking streaming services, media events,
                            and channel subscriptions.  We also actively track out media catalog for the
                            current location(s) of each media item.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                        <Typography color={colors.greenAccent[500]} variant="h3">
                            Your Favorite Question
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant={"h4"}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                            malesuada lacus ex, sit amet blandit leo lobortis eget.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion defaultExpanded>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                        <Typography color={colors.greenAccent[500]} variant="h3">
                            The Final Question
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography variant="h4">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                            malesuada lacus ex, sit amet blandit leo lobortis eget.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </div>
    );
};

export default FAQ;