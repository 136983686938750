import {Alert, Typography} from "@mui/material";
import React from "react";


const DisplayError = ({error, setError}) => {
    return(
        <div>
            {error && (
                <Alert severity="error" style={{ backgroundColor: 'rgba(180,58,58,1)', color: 'white', borderRadius: '10px' }} onClose={() => setError(null)} dismissible>
                    <Typography variant="h5">
                        <strong>Error:</strong> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{error}
                    </Typography>
                </Alert>
            )}
        </div>
    );
}

export default DisplayError;