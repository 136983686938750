import React, {useEffect, useState} from "react";
import {useTheme} from "@mui/material";
import Profile from "./Profile";
import {getUserList} from "../api/api";
import DisplayError from "./DisplayError";
import Header from "./Header";
import {Box} from "@mui/system";
import {tokens} from "../theme";
import {useNavigate} from "react-router-dom";

const ManageProfiles = () => {
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate();


    const getUsers = async () => {
        try {
            const response = await getUserList();
            setUsers(response);
            console.log(response)
            setSelectedUser(response[0].id)
            setLoaded(true);
        } catch (err) {
            if (err && err.message.includes('status code 403')) {
                navigate('/auth')
            }
            setError(err.message)
        }
    }

    useEffect(() => {
        getUsers();
    }, []);

    const handleUserChange = (event) => {
        const selectedUserId = event.target.value;
        setSelectedUser(selectedUserId);
    };

    return (
        <Box m="20px">
            <DisplayError error={error} setError={setError}/>
            {loaded && (
                <div>
                    {/*<Header title="Admin Profile" items={users} current_item={users[0].id} displayField='email' handleSelect={handleUserChange}/>*/}
                    <Header title="Admin Profile" items={users} current_item={selectedUser} displayField='email'
                            handleSelect={handleUserChange}/>
                    {/*<Box sx={{ display: "flex", justifyContent: "center" }}>*/}
                    {/*<FormControl>*/}
                    {/*<InputLabel>Select A User</InputLabel>*/}
                    {/*<Select*/}
                    {/*    value={selectedUser || ""}*/}
                    {/*    onChange={handleUserChange}*/}
                    {/*    sx={{ minWidth: 200, fontSize: "20px", color: colors.greenAccent[400] }}*/}
                    {/*>*/}
                    {/*>*/}
                    {/*    {users.map((user) => (*/}
                    {/*        <MenuItem key={user.id} value={user.id}>*/}
                    {/*            <Typography fontSize={20}>*/}
                    {/*                {user.email}*/}
                    {/*            </Typography>*/}
                    {/*        </MenuItem>*/}
                    {/*    ))}*/}
                    {/*</Select>*/}
                    {/*</FormControl>*/}
                    {/*</Box>*/}

                    {selectedUser ? (
                        <Profile user_id={selectedUser}/>
                    ) : (
                        <Box sx={{display: "flex", justifyContent: "center"}}>
                            {/*<Typography fontSize={20}>Please select a user</Typography>*/}
                        </Box>
                    )}
                </div>
            )}

        </Box>
    );
};

export default ManageProfiles;
