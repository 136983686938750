import React, {useState} from 'react';
import Header from "./Header";
import DisplayError from "./DisplayError";


function About() {
    const [error, setError] = useState(null);

    return (
        <div>
            <DisplayError error={error} setError={setError}/>
            <Header title="About" subtitle="Page Under Constuction"/>
        </div>)
}

export default About