import {Box, IconButton, Tooltip, useTheme} from "@mui/material";
import {useContext, useState} from "react";
import {ColorModeContext, tokens} from "../../theme";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SearchIcon from "@mui/icons-material/Search";
import {LoginOutlined, LogoutOutlined} from "@mui/icons-material";

import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined';
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined';

import {useNavigate} from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import {logout} from "../../api/api";

const Topbar = () => {
    const theme = useTheme();
    const {setAuth} = useAuth();
    const colors = tokens(theme.palette.mode);
    const colorMode = useContext(ColorModeContext);
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState("");

    const goToFullCatalog = () => {
        navigate("/media-catalog")
    }

    const goToCollections = () => {
        navigate("/collections")
    }

    const goToAddMedia = () => {
        navigate("/media-add")
    }

    const goToProfile = () => {
        navigate("/profile")
    }

    const goToCalendar = () => {
        navigate("/calendar")
    }

    const goToFAQ = () => {
        navigate("/faq")
    }

    const goToContact = () => {
        navigate("/contact")
    }

    const goToLogin = () => {
        navigate("/auth")
    }

    async function goToLogout() {
        setAuth(null)
        sessionStorage.setItem('collections', '')
        sessionStorage.setItem('current_collection_id', '')
        await logout()
        navigate("/auth")
    }

    const goToSearch = () => {
        console.log("go to search: (", searchTerm.trim(), ")")
        let holder = searchTerm
        setSearchTerm('')
        if (searchTerm.trim() !== '') {
            // navigate(`/media-add/${searchTerm}`);
            navigate(`/media-add/${holder}`);
        } else {
            navigate('/media-add');
        }
    }

    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
    };

    // useEffect(() => {
    //     const handleThemeChange = () => {
    //         console.log("theme change")
    //     };
    //     theme.emitter.on("change", handleThemeChange);
    //     return () => {
    //         theme.emitter.off("change", handleThemeChange);
    //     };
    // }, []);

    const bgImageLight = "linear-gradient(90deg, rgba(180,28,28,1) 2%, rgba(49,49,116,1) 23%, rgba(105,0,191,1) 33%, rgba(206,239,252,1) 80%"
    const bgImageDark = "linear-gradient(90deg, rgba(180,58,58,1) 2%, rgba(49,49,116,1) 36%, rgba(105,0,161,1) 73%, rgba(166,69,252,1) 100%"

    const backgroundImage = theme.palette.mode === 'dark'
        ? bgImageDark
        : bgImageLight;

    return (
        <Box display="flex" justifyContent="space-between" p={2}
             style={{
                 "backgroundImage": backgroundImage
             }}>
            {/* SEARCH BAR */}
            <Box
                display="flex"
                backgroundColor={colors.primary[400]}
                borderRadius="3px"
            >
                <InputBase sx={{ml: 2, flex: 1}} placeholder="Search" value={searchTerm} onChange={handleInputChange}/>
                <IconButton type="button" sx={{p: 1}} onClick={goToSearch}>
                    <SearchIcon/>
                </IconButton>
            </Box>

            {/* ICONS */}
            <Box display="flex">
                <IconButton onClick={colorMode.toggleColorMode}>
                    {theme.palette.mode === "dark" ? (
                        <DarkModeOutlinedIcon/>
                    ) : (
                        <LightModeOutlinedIcon/>
                    )}
                </IconButton>
                <IconButton onClick={goToFullCatalog}>
                    <Tooltip title="Main Catalog">
                        <AccountBalanceOutlinedIcon/>
                    </Tooltip>
                </IconButton>
                <IconButton onClick={goToCollections}>
                    <Tooltip title="Collections">
                        <AccountBoxOutlinedIcon/>
                    </Tooltip>
                </IconButton>
                <IconButton onClick={goToAddMedia}>
                    <Tooltip title="Add Media">
                        <LibraryAddOutlinedIcon/>
                    </Tooltip>
                </IconButton>
                <IconButton onClick={goToProfile}>
                    <Tooltip title="Profile">
                        <PersonOutlinedIcon/>
                    </Tooltip>
                </IconButton>
                <IconButton onClick={goToCalendar}>
                    <Tooltip title="Calendar">
                        <CalendarTodayOutlinedIcon/>
                    </Tooltip>
                </IconButton>
                <IconButton onClick={goToFAQ}>
                    <Tooltip title="FAQ">
                        <HelpOutlineOutlinedIcon/>
                    </Tooltip>
                </IconButton>
                <IconButton onClick={goToContact}>
                    <Tooltip title="Contact">
                        <ContactPageOutlinedIcon/>
                    </Tooltip>
                </IconButton>
                <IconButton onClick={goToLogin}>
                    <LoginOutlined/>
                </IconButton>
                <IconButton onClick={goToLogout}>
                    <LogoutOutlined/>
                </IconButton>
            </Box>
        </Box>
    );
};

export default Topbar;