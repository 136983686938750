import {useTheme} from "@mui/material";
import {ResponsiveChoropleth} from "@nivo/geo";
import {tokens} from "../theme";
import Header from "../components/Header";
import DisplayError from "../components/DisplayError";
import {Box} from "@mui/system";
import {useEffect, useState} from "react";
import {getUserLocation} from "../api/api";
import country from "../data/usmap.json"


const UserLocation = ({isDashboard = false}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [error, setError] = useState();

    const [chartData, setChartData] = useState([]);
    const [loaded, setLoaded] = useState(false);

    const getChartData = async () => {
        try {
            const response = await getUserLocation();
            // const response = await getApiChartData();
            setChartData(response);
            console.log("GOT USER LOCATION DATA")
            setLoaded(true);
        } catch (err) {
            setError(err.message)
        }
    }

    useEffect(() => {
        console.log("GETTING EFFECT DATA")
        console.log("US: ", country.features)
        getChartData();
    }, []);

    return (
        <div>
            <Box m="20px">
                <Header title={`User Location (${chartData.year})`} subtitle="User Location"/>
                <DisplayError error={error} setError={setError}/>
                <Box height="70vh">
                    {loaded &&
                        <ResponsiveChoropleth
                            data={chartData.data}
                            theme={{
                                axis: {
                                    domain: {
                                        line: {
                                            stroke: colors.grey[100],
                                        },
                                    },
                                    legend: {
                                        text: {
                                            fill: colors.grey[100],
                                        },
                                    },
                                    ticks: {
                                        line: {
                                            stroke: colors.grey[100],
                                            strokeWidth: 1,
                                        },
                                        text: {
                                            fill: colors.grey[100],
                                        },
                                    },
                                },
                                legends: {
                                    text: {
                                        fill: colors.grey[100],
                                    },
                                },
                            }}
                            features={country.features}
                            margin={{top: 0, right: 0, bottom: 0, left: 0}}
                            domain={[0, 1000000]}
                            unknownColor="#666666"
                            label="properties.name"
                            valueFormat=".2s"
                            // projectionScale={isDashboard ? 40 : 150}
                            projectionScale={800}
                            projectionTranslation={[1.95, 1.3]}
                            // projectionTranslation={isDashboard ? [0.49, 0.6] : [0.5, 0.5]}
                            projectionRotation={[0, 0, 0]}
                            borderWidth={2}
                            borderColor="#000000"
                            legends={
                                !isDashboard
                                    ? [
                                        {
                                            anchor: "bottom-left",
                                            direction: "column",
                                            justify: true,
                                            translateX: 20,
                                            translateY: -110,
                                            itemsSpacing: 0,
                                            itemWidth: 94,
                                            itemHeight: 18,
                                            itemDirection: "left-to-right",
                                            itemTextColor: colors.grey[100],
                                            itemOpacity: 0.85,
                                            symbolSize: 18,
                                            effects: [
                                                {
                                                    on: "hover",
                                                    style: {
                                                        itemTextColor: "#ffffff",
                                                        itemOpacity: 1,
                                                    },
                                                },
                                            ],
                                        },
                                    ]
                                    : undefined
                            }
                        />}
                </Box>
            </Box>
        </div>
    );
};

export default UserLocation;