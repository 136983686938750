import {useNavigate} from "react-router-dom"
import React, {useState} from 'react';
import Header from "./Header";
import {Box} from "@mui/system";
import {Button, Typography} from "@mui/material";
import DisplayError from "./DisplayError";

const Missing = () => {
    const navigate = useNavigate();
    const [error, setError] = useState();
    const goBack = () => navigate(-1);
    const goContact = () => navigate("/contact");

    return (
        <div>
            <div>
                <DisplayError error={error} setError={setError}/>
                <Header title="Missing" subtitle="Invalid URL or Page Missing"/>
                <Box m="20px">
                    <Typography variant="h3" align="center" pt="125px">
                        You have selected an invalid URL or a Missing Page
                    </Typography>
                    <Typography align="center" pt="25px">
                        <Box display="flex" justifyContent="center">
                            <Box display="flex">
                                <Button type="button" color="secondary" variant="contained"
                                        onClick={goContact}>
                                    Contact Us
                                </Button>
                            </Box>
                            <Box display="flex" justifyContent="center" pl="20px">
                                <Button type="button" color="secondary" variant="contained"
                                        onClick={goBack}>
                                    Go Back
                                </Button>
                            </Box>
                        </Box>
                    </Typography>
                </Box>
            </div>
        </div>
    )
}

export default Missing