import {useNavigate} from "react-router-dom"
import React, {useState} from 'react';
import DisplayError from "./DisplayError";
import Header from "./Header";
import {Box} from "@mui/system";
import {Button, Typography} from "@mui/material";

const Unauthorized = () => {
    const navigate = useNavigate();
    const [error, setError] = useState();

    const goBack = () => navigate(-1);
    const goContact = () => navigate("/contact");

    return (
        <div>
            <DisplayError error={error} setError={setError} />
            <Header title="Unauthorized" subtitle="Unauthorized Event Report"/>
            <Box m="20px">
                <Typography variant="h3" align="center" pt="125px">
                    You do not have access to this resource
                </Typography>
                <Typography align="center" pt="25px">
                    <Box display="flex" justifyContent="center">
                        <Box display="flex" >
                            <Button type="button" color="secondary" variant="contained"
                                    onClick={goContact}>
                                Contact Us
                            </Button>
                        </Box>
                        <Box display="flex" justifyContent="center" pl="20px">
                            <Button type="button" color="secondary" variant="contained"
                                    onClick={goBack}>
                                Go Back
                            </Button>
                        </Box>
                    </Box>
                </Typography>
            </Box>
        </div>
    )
}

export default Unauthorized