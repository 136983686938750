import React, {useState} from 'react';
import {Alert, Typography} from "@mui/material";
import Header from "./Header";
import DisplayError from "./DisplayError";


function Admin() {
    const [error, setError] = useState(null);

    return (
        <div>
            <DisplayError error={error} setError={setError} />
            <Header title="Admin" subtitle="Page Under Constuction"/>
        </div>)
}

export default Admin