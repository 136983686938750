import {ResponsiveLine} from "@nivo/line";
import {useTheme} from "@mui/material";
import {tokens} from "../theme";
import Header from "../components/Header";
import DisplayError from "../components/DisplayError";
import {Box} from "@mui/system";
import {useEffect, useState} from "react";
import {getUserParticipation} from "../api/api";

const UserParticipation = ({isCustomLineColors = false, isDashboard = false}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [error, setError] = useState();

    const [chartData, setChartData] = useState([]);
    const [loaded, setLoaded] = useState(false);

    const getChartData = async () => {
        try {
            const response = await getUserParticipation();
            // const response = await getApiChartData();
            setChartData(response);
            setLoaded(true);
        } catch (err) {
            setError(err.message)
        }
    }

    useEffect(() => {
        getChartData();
    }, []);

    return (
        <div>
            <Box m="20px">
                <Header title={`User Breakdown (${chartData.year})`} subtitle="User Participation Per Month"/>
                <DisplayError error={error} setError={setError}/>
                <Box height="75vh">
                    {loaded && <ResponsiveLine
                        data={chartData.data}
                        theme={{
                            axis: {
                                domain: {
                                    line: {
                                        stroke: colors.grey[100],
                                    },
                                },
                                legend: {
                                    text: {
                                        fill: colors.grey[100],
                                    },
                                },
                                ticks: {
                                    line: {
                                        stroke: colors.grey[100],
                                        strokeWidth: 1,
                                    },
                                    text: {
                                        fill: colors.grey[100],
                                    },
                                },
                            },
                            legends: {
                                text: {
                                    fill: colors.grey[100],
                                },
                            },
                            tooltip: {
                                container: {
                                    color: colors.primary[500],
                                },
                            },
                        }}
                        colors={isDashboard ? {datum: "color"} : {scheme: "nivo"}} // added
                        margin={{top: 50, right: 110, bottom: 50, left: 60}}
                        xScale={{type: "point"}}
                        yScale={{
                            type: "linear",
                            min: "auto",
                            max: "auto",
                            stacked: true,
                            reverse: false,
                        }}
                        yFormat=" >-.2f"
                        curve="catmullRom"
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                            orient: "bottom",
                            tickSize: 0,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: isDashboard ? undefined : "month", // added
                            legendOffset: 36,
                            legendPosition: "middle",
                        }}
                        axisLeft={{
                            orient: "left",
                            tickValues: 5, // added
                            tickSize: 3,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: isDashboard ? undefined : "count", // added
                            legendOffset: -40,
                            legendPosition: "middle",
                        }}
                        enableGridX={false}
                        enableGridY={false}
                        pointSize={8}
                        pointColor={{theme: "background"}}
                        pointBorderWidth={2}
                        pointBorderColor={{from: "serieColor"}}
                        pointLabelYOffset={-12}
                        useMesh={true}
                        legends={[
                            {
                                anchor: "bottom-right",
                                direction: "column",
                                justify: false,
                                translateX: 100,
                                translateY: 0,
                                itemsSpacing: 0,
                                itemDirection: "left-to-right",
                                itemWidth: 80,
                                itemHeight: 20,
                                itemOpacity: 0.75,
                                symbolSize: 12,
                                symbolShape: "circle",
                                symbolBorderColor: "rgba(0, 0, 0, .5)",
                                effects: [
                                    {
                                        on: "hover",
                                        style: {
                                            itemBackground: "rgba(0, 0, 0, .03)",
                                            itemOpacity: 1,
                                        },
                                    },
                                ],
                            },
                        ]}
                    />}
                </Box>
            </Box>
        </div>
    );
};

export default UserParticipation;