import {Button, TextField, Typography} from "@mui/material";
import {Box} from "@mui/system";
import React, {useEffect, useState} from "react";
import LoginOutlinedIcon from "@mui/icons-material/LoginOutlined"
import HowToRegOutlinedIcon from "@mui/icons-material/HowToRegOutlined"
import useAuth from "../hooks/useAuth";

import { login } from '../api/api';
import {useLocation, useNavigate} from "react-router-dom";
import DisplayError from "./DisplayError";

// const LOGIN_URL = "/user/login";

const Auth = () => {
    const navigate = useNavigate();
    const location = useLocation();

    let from = location.state?.from?.pathname || "/";

    const {setAuth} = useAuth();
    const [isSignup, setIsSignup] = useState(false);
    const [inputs, setInputs] = useState({
        name: "", email: "", password: ""
    });

    const [errMsg, setErrMsg] = useState('');
    const [error, setError] = useState(null);

    useEffect(() => {
        setErrMsg('');
    }, [inputs])

    const handleChange = (e) => {
        setInputs((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value
        }))
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(inputs)
        try {
            const response = await login(inputs.email, inputs.password);
            const roles = response?.roles;
            const user_id = response?.user_id;
            const username = response?.username;
            const fullname = response?.fullname;
            const collections = response?.collections;
            const current_collection_id = response?.current_collection_id;
            const email = inputs.email;

            console.log('roles: ' + roles)
            console.log('collections: ' + JSON.stringify(collections, null, 2))
            console.log('current_collection_id: ' + current_collection_id)
            console.log('username: ' + username)
            console.log('user_id: ' + user_id)
            console.log('fullname: ' + fullname)
            console.log(inputs.email)

            setAuth({email, username, fullname, user_id, roles});
            sessionStorage.setItem('collections', JSON.stringify(collections))
            sessionStorage.setItem('current_collection_id', current_collection_id)
            resetState();
            console.log('from: ' + from)
            if (from == '/') {
                from = '/collections'
            }
            navigate(from, {replace: true});
        } catch (err) {
            setError(err.message)
        }
    }

    const resetState = () => {
        setIsSignup(!isSignup);
        setInputs({name: "", email: "", password: ""});
    }
    return (
        <div>
            <DisplayError error={error} setError={setError}/>
            <form onSubmit={handleSubmit}>
                <Box display="flex"
                     flexDirection={"column"}
                     maxWidth={400}
                     alignItems="center"
                     justifyContent={"center"}
                     margin="auto"
                     marginTop={10}
                     padding={3}
                     borderRadius={5}
                     boxShadow={'5px 5px 10px #ccc'}
                     sx={{
                         ":hover": {
                             boxShadow: '10px 10px 20px #ccc'
                         },
                     }}
                >
                    <Typography variant="h2" padding={3} textAlign="center">
                        {isSignup ? "Signup" : "Login"}
                    </Typography>
                    {isSignup &&
                        <TextField value={inputs.name} onChange={handleChange} name="name" margin="normal" type={"text"}
                                   variant="outlined" placeholder="Name"/>}
                    <TextField value={inputs.email} onChange={handleChange} name="email" margin="normal" type={"email"}
                               variant="outlined" placeholder="Email"/>
                    <TextField value={inputs.password} onChange={handleChange} name="password" margin="normal"
                               type={"password"} variant="outlined" placeholder="Password"/>
                    <Button endIcon={isSignup ? <HowToRegOutlinedIcon/> : <LoginOutlinedIcon/>} type="submit"
                            variant="contained" color="warning" sx={{marginTop: 3, borderRadius: 33}}>
                        {isSignup ? "Signup" : "Login"}
                    </Button>
                    <Button
                        endIcon={isSignup ? <LoginOutlinedIcon/> : <HowToRegOutlinedIcon/>}
                        onClick={resetState}
                        sx={{marginTop: 3, borderRadius: 3}}>
                        Change To {isSignup ? "Login" : "Signup"}
                    </Button>
                </Box>
            </form>
        </div>
    );
};

export default Auth;