import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';
import { useField } from 'formik';

const MultiSelectAutocompleteWrapper = ({ name, options, label, isOptionEqualToValue, ...otherProps }) => {
    const [field, meta, helpers] = useField(name);

    const handleOnChange = (event, newValues) => {
        helpers.setValue(newValues);
    };

    const configAutocomplete = {
        multiple: true,
        options,
        getOptionLabel: (option) => option.label,
        onChange: handleOnChange,
        disableCloseOnSelect: true,
        autoHighlight: true,
        value: field.value || [],
        isOptionEqualToValue: isOptionEqualToValue,
        renderInput: (params) => (
            <TextField
                {...params}
                {...otherProps}
                {...field}
                label={label}
                InputLabelProps={{ shrink: true }}
                error={meta.touched && !!meta.error}
                helperText={meta.touched ? meta.error : ''}
            />
        )
    };

    return <Autocomplete {...configAutocomplete} />;
};

export default MultiSelectAutocompleteWrapper;
