import React from 'react';
import { Outlet } from 'react-router-dom';
import '../App.css';

// const authLinks = [
//     ["Catalog", "/media-catalog", ["admin", "user"]],
//     ["Collections", "/collections", ["admin", "user"]],
//     ["Add Media", "/media-add", ["admin"]],
//     ["Profile", "/profile", ["admin", "user"]],
//     ["Admin", "/admin", ["admin"]]
//   ];
//
//   const nonAuthLinks = [
//     ["Contact", "/contact", []],
//     ["About", "/about", []],
//   ];

const Layout = () => {
    return (
        <main className="app-container">
            {/*<Navbar authLinks={authLinks} nonAuthLinks={nonAuthLinks} />*/}
            <Outlet />
        </main>
    );
};

export default Layout