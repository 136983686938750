import {useLocation, Navigate, Outlet, useNavigate} from "react-router-dom";
import useAuth from "../hooks/useAuth";
import jwtDecode from 'jwt-decode';
import {useEffect} from "react";


const RequireAuth = ({allowedRoles}) => {
    const location = useLocation();
    const {auth} = useAuth();
    // const {access_token} = auth;

    // function isTokenExpired(token) {
    //     let isExpired = false
    //     if (token !== undefined) {
    //         let decodedToken = jwtDecode(token);
    //         let currentTime = Date.now() / 1000; // Convert current time to seconds
    //         // console.log("decoded: ", decodedToken.exp)
    //         // console.log("current: ", currentTime)
    //         isExpired = decodedToken.exp < currentTime;
    //     } else {
    //         console.log("undefined token")
    //     }
    //     console.log("EXPIRED: ", isExpired)
    //     return isExpired
    // }

    // return (
    //     isTokenExpired(access_token) // check if the token is expired
    //         ? <Navigate to="/auth" state={{ from: location }} replace /> // redirect to login page
    //         : auth?.roles?.find(role => allowedRoles?.includes(role)) // check if the user has the required role
    //             ? <Outlet /> // render the child routes
    //             : auth?.email // check if the user is authenticated
    //                 ? <Navigate to="/unauthorized" state={{ from: location }} replace /> // redirect to unauthorized page
    //                 : <Navigate to="/auth" state={{ from: location }} replace /> // redirect to login page
    // );

    return (
        auth?.roles?.find(role => allowedRoles?.includes(role)) // check if the user has the required role
            ? <Outlet /> // render the child routes
            : auth?.email // check if the user is authenticated
                ? <Navigate to="/unauthorized" state={{ from: location }} replace /> // redirect to unauthorized page
                : <Navigate to="/auth" state={{ from: location }} replace /> // redirect to login page
    );

}

export default RequireAuth;