import {Typography, Box, useTheme, Select, MenuItem, TextField} from "@mui/material";
import { tokens } from "../theme";
import React from "react";

const Header = ({ title, subtitle, items, handleSelect, displayField, current_item }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    // let collections = JSON.parse(sessionStorage.getItem('collections'));
    // let current_collection_id = sessionStorage.getItem('current_collection_id');
    return (
        <Box mb="30px" sx={{ display: "flex", alignItems: "center", justifyContent: "center", pr: "20px", pl: "20px" }}>
            <Typography
                variant="h2"
                color={colors.grey[100]}
                fontWeight="bold"
                sx={{ m: "0 0 5px 0" }}
            >
                {title}
            </Typography>
            <Typography variant="h4" color={colors.greenAccent[400]} sx={{ ml: "auto", textAlign: "right" }}>
                {!items && subtitle}

                {items &&
                    <Select
                        value={current_item}
                        onChange={handleSelect}
                        sx={{ minWidth: 100, fontSize: "20px", color: colors.greenAccent[400] }}
                    >
                        {items.map((item, index) => (
                            <MenuItem key={index} value={item.id}>
                                <Typography fontSize={20}>
                                    {item[displayField]}
                                </Typography>
                            </MenuItem>
                        ))}
                    </Select>
                }
            </Typography>
        </Box>
    );
};

export default Header;