import React, {useEffect, useState} from 'react';
import useAuth from '../hooks/useAuth';
import {Card, CardMedia, Grid, useTheme} from "@mui/material";
import { getCatalogDataByGenre, getGenres, setCurrentCollection, addToUserCollection } from '../api/api';
import {ClassNames} from "@emotion/react";
import '../App.css';
import Header from "./Header";
import {useNavigate} from "react-router-dom";
import {tokens} from "../theme";
import DisplayMediaDetails from "./DisplayMediaDetails";
import DisplayError from "./DisplayError";

const MediaCatalog = () => {
    const [movies, setMovies] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [selectedMovie, setSelectedMovie] = useState(null);
    const [genres, setGenres] = useState()
    const [open, setOpen] = useState(false)

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [selectedGenre, setSelectedGenre] = useState(0);

    const navigate = useNavigate();

    const [error, setError] = useState(null);

    const styles = {
        card: {
            borderRadius: '10px', // set the border radius to 10px
        },
        paper: {
            borderRadius: '10px',
        },
    };

    const handleOpen = (movie) => {
        setSelectedMovie(movie);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const getMovies = async () => {
        try {
            const response = await getCatalogDataByGenre(selectedGenre);
            setMovies(response);
            setLoaded(true);
        } catch (err) {
            if (err && err.message.includes('status code 403')) {
                navigate('/auth')
            }
            setError(err.message)
        }
    }

    const handleDropdownSelect = (event) => {
        setSelectedGenre(event.target.value);
        console.log('Selected item: ', event.target.value);
    };


    useEffect(() => {
        async function fetchData() {
            try {
                const response = await getGenres();
                // const data = await response.json();
                setGenres(response);
                console.log("GENRES: ", response)
            } catch (error) {
                // handle error
                console.error("Error fetching data: ", error);
            }
        }
        fetchData();
        getMovies();
    },[]);

    useEffect(() => {
        if (selectedGenre) // To avoid calling getMovies on initial render
            getMovies();
    }, [selectedGenre]);

    const addMedia = async (collection_id) => {
        try {
            const movie_id = selectedMovie.id;
            console.log("ID: ", movie_id);
            await addToUserCollection(collection_id, movie_id);
            // set the collection id to the current collection
            sessionStorage.setItem('current_collection_id', collection_id)
            // await setCurrentCollection(collection_id)
            navigate("/collections")
        } catch (err) {
            handleClose()
            if (err && err.message.includes('status code 403')) {
                navigate('/auth')
            }
            setError(err.message)
        }
    }

    return (
        <div>
            <DisplayError error={error} setError={setError} />
            <Header title="Media Catalog" items={genres} current_item={selectedGenre} displayField='genre'
                    handleSelect={handleDropdownSelect}/>
            <Grid container spacing={1} style={{paddingLeft: 2}}>
                {loaded && movies.map((movie) => (
                    <Grid key={movie.imdb_id} justifyContent="flex-start" alignItems="stretch" item xs={3} md={2.4}
                          lg={2} style={{justifyContent: "center", display: "flex", flexDirection: "column"}}>
                        <div>
                            <Card style={styles.card} onClick={() => handleOpen(movie)}>
                                <CardMedia
                                    className={ClassNames.media}
                                    component="img"
                                    height='300'
                                    width='100%'
                                    image={movie.poster}
                                    alt={movie.title}>
                                </CardMedia>
                            </Card>
                        </div>
                    </Grid>
                ))}
            </Grid>
            { selectedMovie &&
                <DisplayMediaDetails selectedMovie={selectedMovie} open={open} setOpen={setOpen} clickAction={addMedia} clickText={"Add"} add={true} removeAction={null} remove={false}/>
            }
        </div>);
}

export default MediaCatalog