import {
    Box,
    Container, Divider,
    Grid, InputLabel, MenuItem, OutlinedInput, Paper, SelectChangeEvent,
    Table, TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    useTheme
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Header from ".//Header";
import DisplayError from "./DisplayError";
import React, {useEffect, useState} from "react";
import * as Yup from "yup";
import {Form, Formik} from "formik";
import {
    getCollections, getCollection, updateCollection, addCollection, deleteCollection,
} from "../api/api";
import {tokens} from "../theme";
import Textfield from "./FormsUI/Textfield";
import Button from "./FormsUI/Button";
import FormlessButton from "./FormsUI/FormlessButton";

const FORM_VALIDATION = Yup.object().shape({
    name: Yup.string()
        .required('Required'),
    description: Yup.string()
        .required('Required'),
    notes: Yup.string(),
});

const ManageCollections = () => {
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const [error, setError] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [sortFunction, setSortFunction] = React.useState(() => (a, b) => a.id - b.id);

    const [collections, setCollections] = useState([]);
    const [selectedCollection, setSelectedCollection] = useState();

    const retrieveCollections = async () => {
        try {
            console.log("Retrieving collections")
            const response = await getCollections();
            setCollections(response);
            // put into session storage for dropdown lists when dealing with media
            let current_collection_id = parseInt(sessionStorage.getItem('current_collection_id'));
            let idExists = response.some(collection => collection.id === current_collection_id);
            let firstId = null;
            if (!idExists && collections.length > 0) {
                firstId = collections[0].id;
            } else {
                firstId = current_collection_id;
            }
            sessionStorage.setItem('current_collection_id', firstId);
            sessionStorage.setItem('collections', JSON.stringify(response));
            console.log("COLLECTIONS: ", response);
            console.log("CURRENT COLLECTION ID: ", firstId);
            setLoaded(true);
        } catch (err) {
            setError(err.message)
        }
    }

    useEffect(() => {
        retrieveCollections();
    }, []);

    const handleSort = (field) => {
        setSortFunction(() => {
            return (a, b) => {
                if (a[field] < b[field]) return -1;
                if (a[field] > b[field]) return 1;
                return 0;
            };
        });
    };

    const handleAddClick = () => {
        setSelectedCollection({
            id: null,
            name: '',
            description: '',
            notes: '',
        });
    }

    const handleClick = async (event, id) => {
        // Handle the click event, you may want to navigate or load additional data
        console.log(`Row with id ${id} was clicked.`);

        const collectionData = await getCollection(id);
        if (collectionData?.notes === null) {
            collectionData.notes = ''
        }

        setSelectedCollection({
            id: collectionData.id,
            name: collectionData.name,
            description: collectionData.description,
            notes: collectionData.notes,
        });
    };

    const handleSubmit = async (values) => {
        try {
            if (values.id) {
                console.log("update collection")
                await updateCollection(values);
            } else {
                console.log("add collection")
                await addCollection(values);
            }
            await retrieveCollections(); // refreshes the list of collections
        } catch (e) {
            console.error('Error adding/updating collection', e);
        }
        setSelectedCollection(null); // closes the form
    };

    const handleCloseForm = async () => {
        setSelectedCollection(null); // closes the form
    };

    const handleDeleteCollection = async (id) => {
        if (window.confirm("Are you sure?")) {
            console.log("DELETING", id);
            await deleteCollection(id)
            await retrieveCollections();
        }
        setSelectedCollection(null);
    }

    return (
        <>
            <Box m="20px">
                <Grid container>
                    <Grid item xs={12}>
                        <DisplayError error={error} setError={setError}/>
                        <Header title="Manage Collections" subtitle="Manage Collections"/>
                    </Grid>
                    <Grid item xs={12}>
                        <Container maxWidth="md">
                            {/*<div className={classes.formWrapper}>*/}
                            <>
                                {loaded &&
                                    <Grid>
                                        <TableContainer component={Paper} sx={{
                                            minWidth: 650,
                                            maxWidth: '100%',
                                            backgroundColor: 'inherit'
                                        }}>
                                            <Table sx={{minWidth: 650}} aria-label="simple table">
                                                <TableHead>
                                                    <TableRow>
                                                        {/*<TableCell onClick={() => handleSort('id')}>ID</TableCell>*/}
                                                        <TableCell sx={{ fontWeight: 'italic', fontSize: '1.2em', color: colors.greenAccent[400] }}
                                                            onClick={() => handleSort('name')}>Name</TableCell>
                                                        <TableCell sx={{ fontWeight: 'italic', fontSize: '1.2em', color: colors.greenAccent[400] }} onClick={() => handleSort('description')}>Due
                                                            Description</TableCell>
                                                        <TableCell
                                                            sx={{ fontWeight: 'italic', fontSize: '1.2em', color: colors.greenAccent[400] }} onClick={() => handleSort('notes')}>Notes</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {collections.sort(sortFunction).map((row) => (
                                                        <TableRow key={row.id}
                                                                  onClick={(event) => handleClick(event, row.id)}>
                                                            <TableCell component="th" scope="row">{
                                                                row.name}
                                                            </TableCell>
                                                            <TableCell>{row.description}</TableCell>
                                                            <TableCell>{row.notes}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                }
                            </>
                            <Divider sx={{my: 1, height: 2}}/>
                            <div>
                                {!selectedCollection &&
                                    <Grid container spacing={2}>
                                        <Grid item xs={2}>
                                            <FormlessButton onClick={handleAddClick}>
                                                Add
                                            </FormlessButton>
                                        </Grid>
                                    </Grid>
                                }
                            </div>
                            <Divider sx={{my: 2, bgcolor: colors.greenAccent[400], height: 2}}/>
                            {/*<Box sx={{ height: '10px', bgcolor: 'text.primary', my: 10 }} />*/}
                            <div>
                                {loaded &&
                                    <Box>
                                        {selectedCollection ? (
                                            <CollectionForm
                                                key={selectedCollection.id}
                                                initialValues={selectedCollection}
                                                handleSubmit={handleSubmit}
                                                deleteClick={handleDeleteCollection}
                                                closeClick={handleCloseForm}
                                            />
                                        ) : null}
                                    </Box>
                                }
                            </div>
                        </Container>
                    </Grid>
                </Grid>
            </Box>
        </>);
};

const CollectionForm = ({initialValues, handleSubmit, deleteClick, closeClick}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={FORM_VALIDATION}
            enableReinitialize={true}
            onSubmit={(values) => {
                console.log("SUBMITTING")
                handleSubmit(values);
            }}>
            <Form>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h3" sx={{color: colors.greenAccent[400]}}>
                            Collection Details
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Textfield
                            name="name"
                            label="Collection Name"
                        />
                    </Grid>
                    <Grid item xs={9}>
                        <Textfield
                            name="description"
                            label="Description"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Textfield
                            name="notes"
                            label="Notes"
                            multiline={true}
                            rows={2}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Button>
                            Submit Collection
                        </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button onClick={() => deleteClick(initialValues.id)}>
                            Delete Collection
                        </Button>
                    </Grid>
                    <Grid item xs={4}>
                        <Button onClick={closeClick}>
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </Form>
        </Formik>
    )
}
export default ManageCollections;