import {Box, useTheme} from "@mui/material";
import {ResponsiveBar} from "@nivo/bar";
import {tokens} from "../theme";
import Header from "../components/Header";
import {useEffect, useState} from "react";
import {getMovieRevenueByType} from "../api/api";
import DisplayError from "../components/DisplayError";

const MovieRevenueByType = ({isDashboard = false}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [chartData, setChartData] = useState([]);
    const [loaded, setLoaded] = useState(false);

    const [error, setError] = useState(null);

    const getChartData = async () => {
        try {
            const response = await getMovieRevenueByType();
            // const response = await getApiChartData();
            setChartData(response);
            setLoaded(true);
        } catch (err) {
            setError(err.message)
        }
    }

    useEffect(() => {
        getChartData();
    }, []);

    return (
        <div>
            <Box m="20px">
                <Header title="Revenue By Movie Types" subtitle="Movie Type Revenue Breakdown"/>
                <DisplayError error={error} setError={setError}/>
                <Box height="75vh">
                    {loaded && <ResponsiveBar
                        data={chartData}
                        theme={{
                            // added
                            axis: {
                                domain: {
                                    line: {
                                        stroke: colors.grey[100],
                                    },
                                },
                                legend: {
                                    text: {
                                        fill: colors.grey[100],
                                    },
                                },
                                ticks: {
                                    line: {
                                        stroke: colors.grey[100],
                                        strokeWidth: 1,
                                    },
                                    text: {
                                        fill: colors.grey[100],
                                    },
                                },
                            },
                            legends: {
                                text: {
                                    fill: colors.grey[100],
                                },
                            },
                        }}
                        keys={["action", "adventure", "animation", "biography", "comedy", "crime", "documentary", "drama", "family", "fantasy", "game-show", "horror", "mystery", "n/a", "reality-tv", "sci-fi", "short", "sport", "thriller"]}
                        // keys={["hot dog", "burger", "kabab", "donut" ]}
                        indexBy="service"
                        margin={{top: 50, right: 130, bottom: 50, left: 60}}
                        padding={0.3}
                        valueScale={{type: "linear"}}
                        indexScale={{type: "band", round: true}}
                        colors={{scheme: "nivo"}}
                        defs={[
                            {
                                id: "dots",
                                type: "patternDots",
                                background: "inherit",
                                color: "#38bcb2",
                                size: 4,
                                padding: 1,
                                stagger: true,
                            },
                            {
                                id: "lines",
                                type: "patternLines",
                                background: "inherit",
                                color: "#eed312",
                                rotation: -45,
                                lineWidth: 6,
                                spacing: 10,
                            },
                        ]}
                        borderColor={{
                            from: "color",
                            modifiers: [["darker", "1.6"]],
                        }}
                        axisTop={null}
                        axisBottom={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: -30,
                            // legend: isDashboard ? undefined : "service", // changed
                            // legendPosition: "middle",
                            // legendOffset: 60,
                        }}
                        axisLeft={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: isDashboard ? undefined : "revenue", // changed
                            legendPosition: "middle",
                            legendOffset: -50,
                            textOrientation: 'vertical',
                        }}
                        enableLabel={false}
                        labelSkipWidth={12}
                        labelSkipHeight={12}
                        labelTextColor={{
                            from: "color",
                            modifiers: [["darker", 1.6]],
                        }}
                        legends={[
                            {
                                dataFrom: "keys",
                                anchor: "bottom-right",
                                direction: "column",
                                justify: false,
                                translateX: 120,
                                translateY: 0,
                                itemsSpacing: 2,
                                itemWidth: 100,
                                itemHeight: 20,
                                itemDirection: "left-to-right",
                                itemOpacity: 0.85,
                                symbolSize: 20,
                                effects: [
                                    {
                                        on: "hover",
                                        style: {
                                            itemOpacity: 1,
                                        },
                                    },
                                ],
                            },
                        ]}
                        role="application"
                        barAriaLabel={function (e) {
                            return e.id + ": " + e.formattedValue + " in country: " + e.indexValue;
                        }}
                    />}
                </Box>
            </Box>
        </div>
    );
};

export default MovieRevenueByType;