import {
    Box,
    Button,
    Card,
    CardMedia,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    MenuItem,
    Rating,
    Select,
    Typography,
    useTheme
} from "@mui/material";
import {tokens} from "../theme";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {ClassNames} from "@emotion/react";
import {getUserRanking, setUserRanking} from "../api/api";
import DisplayError from "./DisplayError";

const DisplayMediaDetails = ({selectedMovie, open, setOpen, clickAction, clickText, add, removeAction, remove}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [value, setValue] = useState(0);
    const navigate = useNavigate();
    let collections = JSON.parse(sessionStorage.getItem('collections'));
    let current_collection_id = sessionStorage.getItem('current_collection_id');
    const [error, setError] = useState(null);
    const [selectedId, setSelectedId] = useState(current_collection_id);

    const handleOpen = (movie) => {
        console.log("in handle open")
    };
    const styles = {
        card: {
            borderRadius: '10px', // set the border radius to 10px
        },
        paper: {
            borderRadius: '10px',
        },
        hoverText: {
            fontSize: '26px', // Adjust the font size as per your preference
            textTransform: 'capitalize',
        }
    };

    function handleClose() {
        setOpen(false);
    }

    const getRanking = async () => {
        if (selectedMovie) {
            try {
                console.log("selected media: ", selectedMovie['id'])
                const response = await getUserRanking(selectedMovie['id']);
                console.log("get ranking value:", response['rank']);
                setValue(response['rank'])
            } catch (err) {
                if (err && err.message.includes('status code 403')) {
                    navigate('/auth')
                }
                console.log(err.message)
                setError(err.message)
            }
        }
    }


    const setRanking = async (rank) => {
        try {
            console.log("selected media: ", selectedMovie['id'])
            const response = await setUserRanking(selectedMovie['id'], rank);
        } catch (err) {
            if (err && err.message.includes('status code 403')) {
                navigate('/auth')
            }
            console.log(err.message)
            setError(err.message)
        }
    }

    const handleRankingChange = (event, newValue) => {
        console.log('New Ranking: ', newValue);
        setValue(newValue)
        setRanking(newValue)
    };

    useEffect(() => {
        if (selectedMovie) {
            getRanking()
        }
        // setValue(9.5)
    }, [selectedMovie])

    return (
        <Dialog PaperProps={{style: styles.paper}} open={open} onClose={handleClose}>
            <DisplayError error={error} setError={setError}/>
            <DialogTitle style={{background: 'rgba(166,69,252,1)'}}>
                {/* <Avatar src={selectedMovie?.poster}/> */}
                <Typography style={{
                    display: 'flex',
                    alignItems: 'center',
                    fontWeight: 'bold',
                    color: 'white',
                    fontVariant: 'small-caps',
                    fontStyle: 'italic',
                    fontSize: '4em',
                    textShadow: '2px 2px 2px rgba(0, 0, 0, 0.3)',
                    fontFamily: 'Garamond, serif',
                }}>
                    <a href={selectedMovie?.poster} target="_blank" rel="noopener norefeerrer">
                        <img src={selectedMovie?.poster} alt="poster"
                             style={{
                                 height: "80px",
                                 width: "50px", marginRight: "20px", borderRadius: '10%', border: '1px solid white'
                             }}/>
                    </a>
                    {selectedMovie?.title}
                </Typography>
            </DialogTitle>
            {selectedMovie && selectedMovie.streaming_sources && (
                <Box style={{display: 'flex', flexWrap: 'wrap'}}>
                    {selectedMovie.streaming_sources.map((source, index) => (
                        <Card
                            key={index}
                            style={{...styles.card, width: 'auto', height: 'auto', borderRadius: '10px'}}
                            onClick={() => handleOpen(selectedMovie)}
                        >
                            <CardMedia
                                className={ClassNames.media}
                                component="img"
                                height="50px"
                                width="50px"
                                image={source['url']}
                                title={source['type'] + ":" + source['price']}
                                alt={selectedMovie.title}
                            />
                            <span className={styles.hoverText}>{source['type']}</span>
                        </Card>
                    ))}
                </Box>
            )}
            <DialogContent style={{background: colors.grey[900]}}>
                <Typography
                    style={{color: colors.greenAccent[300], marginTop: '10px', fontSize: '20px'}}>
                    Cast
                </Typography>
                <Typography variant="body" style={{color: colors.primary[100], fontSize: '18px'}}>
                    {selectedMovie?.actors}
                </Typography>
                <Typography
                    style={{color: colors.greenAccent[300], marginTop: '10px', fontSize: '20px'}}>
                    Writer
                </Typography>
                <Typography variant="body" style={{color: colors.primary[100], fontSize: '18px'}}>
                    {selectedMovie?.writer}
                </Typography>
                <Typography
                    style={{color: colors.greenAccent[300], marginTop: '10px', fontSize: '20px'}}>
                    Director
                </Typography>
                <Typography variant="body" style={{color: colors.primary[100], fontSize: '18px'}}>
                    {selectedMovie?.director}
                </Typography>
                <Typography
                    style={{color: colors.greenAccent[300], marginTop: '10px', fontSize: '20px'}}>
                    Genre
                </Typography>
                <Typography variant="body" style={{color: colors.primary[100], fontSize: '18px'}}>
                    {selectedMovie?.genre}
                </Typography>
                <Typography
                    style={{color: colors.greenAccent[300], marginTop: '10px', fontSize: '20px'}}>
                    Rating
                </Typography>
                <Typography variant="body" style={{color: colors.primary[100], display: 'block', fontSize: '18px'}}>
                    {selectedMovie?.rating} &nbsp;&nbsp;&nbsp; {selectedMovie?.votes} votes
                </Typography>
                <Typography
                    style={{color: colors.greenAccent[300], marginTop: '10px', fontSize: '20px'}}>
                    Year
                </Typography>
                <Typography variant="body" style={{color: colors.primary[100], display: 'block', fontSize: '18px'}}>
                    {selectedMovie?.year}
                </Typography>
                <Typography
                    style={{color: colors.greenAccent[300], marginTop: '10px', fontSize: '20px'}}>
                    MPAA Rating
                </Typography>
                <Typography variant="body" style={{color: colors.primary[100], display: 'block', fontSize: '18px'}}>
                    {selectedMovie?.rated}
                </Typography>
                <Typography
                    style={{color: colors.greenAccent[300], marginTop: '10px', fontSize: '20px'}}>
                    Box Office
                </Typography>
                <Typography variant="body" style={{color: colors.primary[100], display: 'block', fontSize: '18px'}}>
                    {selectedMovie?.box_office}
                </Typography>
                <Typography
                    style={{color: colors.greenAccent[300], marginTop: '10px', fontSize: '20px'}}>
                    Plot
                </Typography>
                <Typography variant="body" style={{color: colors.primary[100], fontSize: '18px'}}>
                    {selectedMovie?.plot}
                </Typography>
            </DialogContent>
            <DialogActions style={{background: 'rgba(105,0,161,1)'}}>
                <Grid container justify='flex-start'>
                    <Rating
                        name="customized-10"
                        defaultValue={0}
                        max={5}
                        precision={0.5}
                        // name="simple-controlled"
                        value={value}
                        onChange={handleRankingChange}
                    />
                </Grid>

                {add && (
                    <Select value={selectedId}
                            onChange={e => setSelectedId(e.target.value)}
                            variant="outlined"
                            style={{
                                fontWeight: '400',
                                textAlign: 'center',
                                // color: 'rgba(105,0,161,1)',
                                color: 'white',
                                backgroundColor: 'rgba(105,0,161,1)',
                                // fontVariant: 'small-caps',
                                // fontStyle: 'italic',
                                border: '1px solid #FFFFFF',
                                height: '36px',
                                borderRadius: '5px'
                            }}>
                        {collections.map(item => (
                            <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                        ))}
                    </Select>
                )}
                {clickAction && <Button onClick={() => clickAction(selectedId)} style={{
                    border: '1px solid #FFFFFF',
                    marginLeft: '20px'
                }}>
                    <Typography variant="h5" style={{
                        // fontWeight: 'bold',
                        color: 'white',
                        fontVariant: 'small-caps',
                        // fontStyle: 'italic'
                    }}>
                        {clickText}
                    </Typography>
                </Button>}
                { remove && (
                    <Button onClick={removeAction} style={{border: '1px solid #FFFFFF'}}>
                        <Typography variant={"h5"} style={{
                            // fontWeight: 'bold',
                            color: 'white',
                            fontVariant: 'small-caps',
                            // fontStyle: 'italic'
                        }}>
                            Remove
                        </Typography>
                    </Button>
                )}
                <Button onClick={handleClose} style={{border: '1px solid #FFFFFF'}}>
                    <Typography variant={"h5"} style={{
                        // fontWeight: 'bold',
                        color: 'white',
                        fontVariant: 'small-caps',
                        // fontStyle: 'italic'
                    }}>
                        Close
                    </Typography>
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DisplayMediaDetails;