import {Box, Container, Grid, Typography, useTheme} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import DisplayError from "./DisplayError";
import React, {useEffect, useState} from "react";
import * as Yup from "yup";
import {Form, Formik} from "formik";
import {tokens} from "../theme";
import {useNavigate} from "react-router-dom";
import Textfield from "./FormsUI/Textfield";
import Checkbox from "./FormsUI/Checkbox";
import Button from "./FormsUI/Button";
import states from "../data/states.json";
import Select from "./FormsUI/Select";
import {getUserProfile, getZipDetails, updateProfile} from "../api/api";


const FORM_VALIDATION = Yup.object().shape({
    email: Yup.string(),
    firstname: Yup.string()
        .required('Required'),
    lastname: Yup.string()
        .required('Required'),
    username: Yup.string(),
    phone: Yup.string()
        .nullable()
        .typeError('Please enter a valid phone number'),
    addressLine1: Yup.string()
        .nullable(),
    addressLine2: Yup.string()
        .nullable(),
    city: Yup.string()
        .nullable(),
    state: Yup.string()
        .nullable(),
    zipcode: Yup.string()
        .nullable(),
    active: Yup.boolean(),
    verified: Yup.boolean(),
    agreeToTerms: Yup.boolean()
        .oneOf([true], 'The terms and conditions must be accepted.')
        .required('The terms and conditions must be accepted.'),
});


const Profile = (props) => {
    const {user_id} = props
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const [error, setError] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate();

    const handleUpdateZip = async (event) => {
        const updatedTextValue = event.target.value;
        if (updatedTextValue.length == 5) {
            const zipFormValues = {...formValues, zipcode: event.target.value};
            setFormValues(zipFormValues);
            const zip_data = await getZipDetails(updatedTextValue);
            const newFormValues = {
                ...formValues,
                zipcode: event.target.value,
                city: zip_data.city,
                state: zip_data.state
            };
            setFormValues(newFormValues);
            formValues.zipcode = updatedTextValue;
        } else {
            console.log("VALUE: ", event.target.value)
            const newFormValues = {...formValues, zipcode: event.target.value, city: "", state: ""};
            setFormValues(newFormValues);
        }
    };

    const getProfile = async () => {
        try {
            console.log("getting user profile: ", user_id);
            const response = await getUserProfile(user_id);
            setFormValues(response);
            setLoaded(true);
        } catch (err) {
            setError(err.message)
        }
    }

    useEffect(() => {
        setLoaded(false);
        getProfile();
    }, [user_id]);

    // const [formValues, setFormValues] = useState(initialValues);
    const [formValues, setFormValues] = useState();

    return (
        <Box m="20px">
            <Grid container>
                <Grid item xs={12}>
                    <DisplayError error={error} setError={setError}/>
                    {/*<Header title="Profile" subtitle="Edit Personal Profile"/>*/}
                </Grid>
                <Grid item xs={12}>
                    <Container maxWidth="md">
                        {/*<div className={classes.formWrapper}>*/}
                        <div>
                            {loaded &&
                                <Box>
                                    <Formik
                                        initialValues={formValues}
                                        validationSchema={FORM_VALIDATION}
                                        onSubmit={values => {
                                            values.zipcode = formValues.zipcode;
                                            values.state = formValues.state;
                                            values.city = formValues.city;
                                            console.log("VALUES: ", values)
                                            updateProfile(values)
                                        }}>
                                        <Form>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <Typography>
                                                        Your Details
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Textfield
                                                        name="email"
                                                        label="Email"
                                                        disabled={true}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Textfield
                                                        name="firstname"
                                                        label="First Name"
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Textfield
                                                        name="lastname"
                                                        label="Last Name"
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Textfield
                                                        name="username"
                                                        label="Username"
                                                        disabled={true}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Textfield
                                                        name="phone"
                                                        label="Phone"
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <Textfield
                                                        name="city"
                                                        label="City"
                                                        value={formValues.city}
                                                        disabled={true}
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Select
                                                        name="state"
                                                        label="State"
                                                        value={formValues.state}
                                                        options={states}
                                                        disabled={true}
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Textfield
                                                        name="zipcode"
                                                        label="Zip Code"
                                                        value={formValues.zipcode}
                                                        onChange={handleUpdateZip}
                                                    />
                                                </Grid>
                                                {formValues.agreeToTerms != true &&
                                                    <Grid item xs={3}>
                                                        <Checkbox
                                                            name="agreeToTerms"
                                                            legend="Terms Of Service"
                                                            label="I Agree"
                                                            // checked={formValues.agreeToTerms}
                                                            // color="default"
                                                        />
                                                    </Grid>
                                                }
                                                <Grid item xs={3}>
                                                    <Checkbox
                                                        name="active"
                                                        legend="Active Member"
                                                        label="Active Member"
                                                        checked={formValues.active}
                                                        disabled
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Checkbox
                                                        name="verified"
                                                        legend="Verified Member"
                                                        label="Verified Member"
                                                        checked={formValues.verified}
                                                        disabled
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Button>
                                                        Submit Form
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Form>
                                    </Formik>
                                </Box>
                            }
                        </div>
                    </Container>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Profile;