import {useEffect, useState} from "react";
import FullCalendar from "@fullcalendar/react";
import {formatDate} from '@fullcalendar/core'
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import {Box, List, ListItem, ListItemText, Typography, useTheme,} from "@mui/material";
import Header from "./Header";
import {tokens} from "../theme";
import {getCalendarEventsForCatalog} from "../api/api";
import DisplayError from "./DisplayError";

const Calendar = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [currentEvents, setCurrentEvents] = useState([]);
    const [initialEvents, setInitialEvents] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        console.log("page loaded")
        const fetchData = async () => {
            await getCalendarEvents();
            console.log("INIT: ", initialEvents)
        }
        fetchData();
    }, []);

    const getCalendarEvents = async () => {
        try {
            const response = await getCalendarEventsForCatalog();
            console.log("GET RESPONSE: ", response);
            setInitialEvents(response);
            setCurrentEvents(response)
            console.log("INITIAL EVENTS UPDATED: ", response);
            setLoaded(true);
        } catch (err) {
            setError(err.message)
        }
    }
    const handleDateClick = (selected) => {
        const title = prompt("Please enter a new title for your event");
        const calendarApi = selected.view.calendar;
        calendarApi.unselect();

        if (title) {
            calendarApi.addEvent({
                id: `${selected.dateStr}-${title}`,
                title,
                start: selected.startStr,
                end: selected.endStr,
                allDay: selected.allDay,
            });
        }
    };

    const handleEventClick = (selected) => {
        console.log("selected: ", selected.event.id)
        // if (
        //     window.confirm(
        //         `Are you sure you want to delete the event '${selected.event.title}'`
        //     )
        // ) {
        //     selected.event.remove();
        // }
    };

    return (
        <div>
            <DisplayError error={error} setError={setError}/>
            <Box m="20px">
                <Header title="Calendar" subtitle="Full Calendar Interactive Page"/>

                <Box display="flex" justifyContent="space-between">
                    {/* CALENDAR SIDEBAR */}
                    <Box
                        flex="1 1 20%"
                        backgroundColor={colors.primary[400]}
                        p="15px"
                        borderRadius="4px"
                    >
                        <Typography variant="h5">Events</Typography>
                        <List>
                            {currentEvents.map((event) => (
                                <ListItem
                                    key={event.id}
                                    sx={{
                                        backgroundColor: colors.greenAccent[500],
                                        margin: "10px 0",
                                        borderRadius: "2px",
                                    }}
                                >
                                    <ListItemText
                                        primary={event.title}
                                        secondary={
                                            <Typography>
                                                {formatDate(event.start, {
                                                    year: "numeric",
                                                    month: "short",
                                                    day: "numeric",
                                                })}
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </Box>

                    {/* CALENDAR */}
                    {loaded && (
                        <Box flex="1 1 100%" ml="15px">
                            <FullCalendar
                                height="75vh"
                                plugins={[
                                    dayGridPlugin,
                                    timeGridPlugin,
                                    interactionPlugin,
                                    listPlugin,
                                ]}
                                headerToolbar={{
                                    left: "prev,next today",
                                    center: "title",
                                    right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
                                }}
                                initialView="dayGridMonth"
                                editable={true}
                                selectable={true}
                                selectMirror={true}
                                dayMaxEvents={true}
                                select={handleDateClick}
                                eventClick={handleEventClick}
                                eventsSet={(events) => setCurrentEvents(events)}
                                initialEvents={initialEvents}
                            />
                        </Box>
                    )}
                </Box>
            </Box>
        </div>
    );
};

export default Calendar;