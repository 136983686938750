import Auth from "./components/Auth"
import {Helmet, HelmetProvider} from 'react-helmet-async';
import {Route, Routes} from 'react-router-dom';
import Layout from './components/Layout';
import MediaCatalog from './components/MediaCatalog';
import Collections from './components/Collections';
import Register from './components/Register';
import Contact from './components/Contact';
import Admin from './components/Admin';
import Missing from './components/Missing';
// import {createTheme} from "@mui/system";
import ManageProfile from "./components/ManageProfile";
import ManageProfiles from "./components/ManageProfiles";
import MediaAdd from "./components/MediaAdd";
import Unauthorized from "./components/Unauthorized";
import RequireAuth from "./components/RequireAuth";
import About from "./components/About";
import {ColorModeContext, useMode} from "./theme";
import {CssBaseline, ThemeProvider} from "@mui/material";
import Topbar from "./components/global/Topbar";
import {useState} from "react";
import Sidebar from "./components/global/Sidebar";
import Calendar from "./components/Calendar"
import FAQ from "./components/FAQ";
import MovieRevenueByType from "./charts/MovieRevenueByType";
import UserParticipation from "./charts/UserParticipation";
import StreamingServiceParticipation from "./charts/StreamingServiceParticipation";
import UserLocation from "./charts/UserLocation";
import ManageClubs from "./components/ManageClubs";
import {createTheme} from "@mui/material/styles";
import ManageSubscriptions from "./components/ManageSubscriptions";
import ManageCollections from "./components/ManageCollections";

function App() {
    const [theme, colorMode] = useMode();
    const [isSidebar, setIsSidebar] = useState(true);

    const modifiedTheme = createTheme({
        ...theme,
        overrides: {
            MuiButton: {
                label: {
                    color: 'black',
                }
            }
        }
    });

    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={modifiedTheme}>
                <CssBaseline>
                    <div className="app">
                        <Sidebar isSidebar={isSidebar}/>
                        <main className="content">
                            <Topbar setIsSidebar={setIsSidebar}/>
                            <HelmetProvider>
                                <Helmet>
                                    <meta charSet="utf-8"/>
                                    <title>Couch Ranking</title>
                                    <link rel="canonical" href="http://www.couchranking.com"/>
                                    <meta name="description" content="Ranking and sourcing media"/>
                                </Helmet>
                                <Routes>
                                    {/* Any routes here need to be added to the navbar in layouts */}
                                    <Route path="/" element={<Layout/>}>
                                        {/* public routes */}
                                        <Route path="auth" element={<Auth/>}/>
                                        <Route path="register" element={<Register/>}/>
                                        <Route path="contact" element={<Contact/>}/>
                                        <Route path="about" element={<About/>}/>
                                        <Route path="faq" element={<FAQ/>}/>
                                        <Route path="unauthorized" element={<Unauthorized/>}/>

                                        {/* these routes are protected */}
                                        <Route element={<RequireAuth allowedRoles={['user', 'admin']}/>}>
                                            <Route path="media-add/:term" element={<MediaAdd/>}/>
                                            <Route path="media-add" element={<MediaAdd/>}/>
                                            <Route path="media-catalog" element={<MediaCatalog/>}/>
                                            <Route path="collections" element={<Collections/>}/>
                                            <Route path="profile" element={<ManageProfile/>}/>
                                            <Route path="/calendar" element={<Calendar/>}/>
                                            <Route path="/clubs" element={<ManageClubs/>}/>
                                            <Route path="/manage-subscriptions" element={<ManageSubscriptions/>}/>
                                            <Route path="/manage-collections" element={<ManageCollections/>}/>
                                        </Route>

                                        {/* these routes are protected */}
                                        <Route element={<RequireAuth allowedRoles={['admin']}/>}>
                                            <Route path="admin" element={<Admin/>}/>
                                            <Route path="/manage-profiles" element={<ManageProfiles/>}/>
                                            <Route path="/movie-type-chart" element={<MovieRevenueByType/>}/>
                                            <Route path="/user-participation-chart" element={<UserParticipation/>}/>
                                            <Route path="/streaming-service-participation-chart"
                                                   element={<StreamingServiceParticipation/>}/>
                                            <Route path="/user-location-chart" element={<UserLocation/>}/>
                                        </Route>

                                        {/* catch all */}
                                        <Route path="*" element={<Missing/>}/>
                                    </Route>
                                </Routes>
                            </HelmetProvider>
                        </main>
                    </div>
                </CssBaseline>
            </ThemeProvider>
        </ColorModeContext.Provider>
    )
        ;
}

export default App;
